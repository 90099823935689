import { useState, useEffect } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { CustomInput } from 'components';
import { useFormContext } from 'react-hook-form';

interface AddressComponent {
  long_name: string;
  types: string[];
}
interface SelectedPlace {
  street: string;
  city: string;
  state: string;
  zipCode: string;
}
interface PlaceDetails {
  address_components?: AddressComponent[];
}
export const Address = () => {
  const methods = useFormContext();
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      debounce: 200,
      language: 'en',
      apiKey: process.env.NEXT_PUBLIC_MAPS_API,
      options: {
        componentRestrictions: { country: 'us' },
        types: ['address'],
      },
    });
  const [address, setAddress] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [showmodal, setModal] = useState(false);
  useEffect(() => {
    if (placePredictions.length) {
      const selectedPrediction = placePredictions.find(
        (prediction) => prediction.place_id === address.place_id,
      );
      if (selectedPrediction) {
        placesService?.getDetails(
          {
            placeId: selectedPrediction.place_id,
          },
          (placeDetails) => savePlaceDetailsToState(placeDetails),
        );
        // methods.setValue('street',, {
        //   shouldDirty: true,
        // });
      }
    }
  }, [address]);

  const savePlaceDetailsToState = (placeDetails: PlaceDetails): void => {
    const addressComponents = placeDetails?.address_components || [];
    const street = getStreetName(addressComponents);
    const city = getAddressComponentValue(addressComponents, 'locality');
    const state = getAddressComponentValue(addressComponents, 'administrative_area_level_1');
    const zipCode = getAddressComponentValue(addressComponents, 'postal_code');
    const selectedPlace: SelectedPlace = { street, city, state, zipCode };
    setSelectedPlace(selectedPlace);
  };
  const getAddressComponentValue = (addressComponents: AddressComponent[], type: string) => {
    const component = addressComponents.find((component) => component.types.includes(type));
    return component ? component.long_name : '';
  };
  function getStreetName(addressComponents: AddressComponent[]): string {
    const streetNumberComponent = addressComponents.find((component) =>
      component.types.includes('street_number'),
    );
    const streetNumber = streetNumberComponent?.long_name || '';

    const streetNameComponent = addressComponents.find((component) =>
      component.types.includes('route'),
    );
    const streetName = streetNameComponent?.long_name || '';

    return streetNumber + ' ' + streetName;
  }

  const handleChange = (event) => {
    getPlacePredictions({
      input: event.target.value,
    });
    setSelectedPlace({
      ...selectedPlace,
      street: event.target.name === 'street' ? event.target.value : selectedPlace?.street,
    });
    methods.setValue('street', selectedPlace?.street);
    // methods.trigger(['street', 'city', 'state', 'zipCode']);
    setModal(true);
  };

  const handleManualChange = (event) => {
    setSelectedPlace({
      ...selectedPlace,
      city: event.target.name === 'city' ? event.target.value : selectedPlace?.city,
      state: event.target.name === 'state' ? event.target.value : selectedPlace?.state,
      zipCode: event.target.name === 'zipCode' ? event.target.value : selectedPlace?.zipCode,
    });
    methods.setValue(
      'city',
      event.target.name === 'city' ? event.target.value : selectedPlace?.city,
    );
    methods.setValue(
      'state',
      event.target.name === 'state' ? event.target.value : selectedPlace?.state,
    );
    methods.setValue(
      'zipCode',
      event.target.name === 'zipCode' ? event.target.value : selectedPlace?.zipCode,
    );
    methods.trigger(['city', 'state', 'zipCode']);
  };

  return (
    <div className="pb-8 flex flex-col gap-6 mt-6">
      <div className="relative">
        <CustomInput.Input
          label="Street"
          placeholder="Street and number, Apt, Unit, etc."
          className="w-full"
          name="street"
          value={selectedPlace?.street}
          // validations={{ required: true }}
          onChange={handleChange}
          autoComplete="true"
        />
        {placePredictions.length > 0 && showmodal === true ? (
          <div className="absolute z-10 w-full mt-1 bg-white-100 border border-gray-300 rounded-md shadow-lg overflow-y-auto max-h-[250px]">
            {placePredictions.map((prediction) => (
              <div
                key={prediction.place_id}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  setAddress(prediction);
                  setModal(false);
                }}
              >
                {prediction.description}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <CustomInput.Input
        label="Apartment / Suite / Unit"
        placeholder="Enter Apartment / Suite / Unit Number"
        className="w-full"
        name="apartment"
        autoComplete="true"
      />
      <CustomInput.Input
        label="City"
        placeholder="Enter city"
        className="w-full"
        name="city"
        // validations={{ required: true }}
        autoComplete="true"
        value={selectedPlace?.city}
        onChange={handleManualChange}
      />

      <div className="grid grid-cols-2 gap-4">
        {/* <CustomInput.State value={address.state} /> */}
        <CustomInput.Input
          placeholder="Enter State"
          name="state"
          label="State"
          // validations={{ required: true }}
          autoComplete="true"
          value={selectedPlace?.state}
          onChange={handleManualChange}
        />
        <CustomInput.Input
          mask="99999"
          placeholder="Enter your ZIP"
          name="zipCode"
          label="ZIP code"
          // validations={{ required: true }}
          value={selectedPlace?.zipCode}
          onChange={handleManualChange}
        />
      </div>
    </div>
  );
};
