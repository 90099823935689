import { commonRoutes } from '@utils';
import { Button } from 'components/button';
import { TermsModal, useOpenTermsModal } from 'components/modal/terms-modal';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';

export const DesktopIntroduction = () => {
  const { setOpenTermsModal } = useOpenTermsModal();
  const intl = useIntl();
  const router = useRouter();
  const isMobile = useMediaQuery({ minWidth: 1024 });

  return (
    <div className="h-full px-4 mx-auto max-w-7xl">
      <TermsModal />
      {isMobile ? (
        <div className="mx-[9.375rem] h-full min-w-[320px]">
          <div className="flex flex-col basis-1/2">
            <header className="py-6">
              <Link href={commonRoutes.buyer.getStarted}>
                <a>
                  <Image
                    src="https://mhp-icons.s3.amazonaws.com/mint-lockup.png"
                    alt="Logo"
                    width={180}
                    height={80}
                    objectFit="contain"
                  />
                </a>
              </Link>
            </header>
            <div className="items-center flex flex-row mb-[3.125rem] mt-[6.25rem] min-w-[450px] min-h-[450px]">
              <div className="rounded-2xl mr-20 min-w-[300px] min-h-[400px] pt-[3rem]">
                <Image
                  priority
                  alt={intl.formatMessage({ id: 'getstarted.image-alt' })}
                  width={520}
                  height={450}
                  className={'rounded-2xl'}
                  src="/images/getstarted.png"
                  quality={100}
                />
              </div>

              <div>
                <h1 className="font-normal text-4xl font-slab w-[520px] leading-[47px] mb-5">
                  {intl.formatMessage({ id: 'getstarted.title' })}
                </h1>
                <h1 className="font-bold text-4xl text-primary font-slab w-[400px] leading-[47px] mb-5">
                  {intl.formatMessage({ id: 'getstarted.siteTitle' })}
                </h1>
                <p>
                  Helping you achieve financial freedom through homeownership with the tools and
                  education you need to succeed
                </p>
                <div>
                  <Button
                    onClick={() => {
                      router.push(commonRoutes.buyer.welcome);
                    }}
                    className="w-[199px] mt-10"
                  >
                    {intl.formatMessage({ id: 'getstarted.button' })}
                  </Button>
                </div>
              </div>
            </div>

            <div className="bg-card rounded-md shadow-[0_10px_15px_rgba(14, 52, 42, 0.08)] items-center justify-center p-4 mb-11">
              <p className="my-2 text-sm text-center">As seen in</p>
              <div className="flex items-center flex-shrink my-2 container-default justify-evenly">
                <Link href={commonRoutes.forbes} passHref>
                  <a target="_blank" className="w-[81px] h-[30px]">
                    <Image
                      alt={intl.formatMessage({ id: 'getstarted.image-alt' })}
                      width={81}
                      height={30}
                      layout="responsive"
                      src="/images/forbes.png"
                    />
                  </a>
                </Link>

                <Link href={commonRoutes.refinery} passHref>
                  <a target="_blank" className="w-[95px] h-[30px]">
                    <Image
                      alt={intl.formatMessage({ id: 'getstarted.image-alt' })}
                      width={95}
                      height={30}
                      layout="responsive"
                      className={''}
                      src="/images/refinery.png"
                    />
                  </a>
                </Link>

                <Link href={commonRoutes.realSimple} passHref>
                  <a target="_blank" className="w-[126px] h-[30px]">
                    <Image
                      alt={intl.formatMessage({ id: 'getstarted.image-alt' })}
                      width={126}
                      height={30}
                      layout="responsive"
                      src="/images/realsimple.png"
                    />
                  </a>
                </Link>
              </div>
              <div className="flex items-center flex-shrink my-2 container-default justify-evenly">
                <Link href={commonRoutes.next} passHref>
                  <a target="_blank" className="w-[95px] h-[30px]">
                    <Image
                      alt={intl.formatMessage({ id: 'getstarted.image-alt' })}
                      width={95}
                      height={30}
                      layout="responsive"
                      src="/images/next.png"
                    />
                  </a>
                </Link>
                <Link href={commonRoutes.nationalmortgageprofessional} passHref>
                  <a target="_blank" className="w-[500px] h-[18px]">
                    <Image
                      alt={intl.formatMessage({ id: 'getstarted.image-alt' })}
                      width={500}
                      height={18}
                      layout="responsive"
                      src="/images/nationalmortgage.png"
                    />
                  </a>
                </Link>
                <Link href={commonRoutes.housingWire} passHref>
                  <a target="_blank" className="w-[30px] h-[30px]">
                    <Image
                      alt={intl.formatMessage({ id: 'getstarted.image-alt' })}
                      width={30}
                      height={30}
                      layout="responsive"
                      src="/images/hw.png"
                    />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-full px-4 mx-auto max-w-7xl">
          <div className="w-full h-full mx-4 lg:mx-auto lg:max-w-screen-md xs:mx-2">
            <div className="flex flex-col">
              <header className="py-6">
                <Link href={commonRoutes.buyer.getStarted}>
                  <a>
                    <Image
                      src="https://mhp-icons.s3.amazonaws.com/mint-lockup.png"
                      alt="Logo"
                      width={120}
                      height={60}
                      objectFit="contain"
                    />
                  </a>
                </Link>
              </header>
              <div className="flex mb-[3.125rem] xs:mb-[2.725rem] mt-[4rem] xs:mt-[2rem] flex-col md:flex-row items-center justify-center ">
                <div className="rounded-2xl mb-6 w-full md:w-1/2 lg:min-w-[250px] lg:min-h-[275] lg:scale-90 sm:scale-75 mx-auto md:mx-0">
                  <Image
                    priority
                    alt={intl.formatMessage({ id: 'getstarted.image-alt' })}
                    width={520}
                    height={450}
                    className="rounded-2xl"
                    src="/images/getstarted.png"
                    quality={100}
                  />
                </div>

                <div className="w-full text-center md:w-1/2 md:pl-4 md:text-left">
                  <h1 className="font-normal text-4xl sm:text-3xl xs:text-3xl font-slab w-full md:w-[520px] leading-[47px] xs:leading-[25px] sm:leading-[30px] mb-5">
                    {intl.formatMessage({ id: 'getstarted.title' })}
                  </h1>
                  <h1 className="font-bold text-4xl sm:text-3xl xs:text-3xl text-primary font-slab w-full md:w-[400px] leading-[47px] sm:leading-[30px] xs:leading-[25px] mb-5">
                    {intl.formatMessage({ id: 'getstarted.siteTitle' })}
                  </h1>
                  <p>
                    Helping you achieve financial freedom through homeownership with the tools and
                    education you need to succeed
                  </p>
                  <div>
                    <Button
                      onClick={() => {
                        router.push(commonRoutes.buyer.welcome);
                      }}
                      className="w-[199px] mt-10 mx-auto md:mx-0"
                    >
                      {intl.formatMessage({ id: 'getstarted.button' })}
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-card rounded-md shadow-[0_10px_15px_rgba(14, 52, 42, 0.08)] items-center justify-center p-4 mb-11">
              <p className="my-2 text-sm text-center">As seen in</p>
              <div className="flex items-center flex-shrink my-2 container-default justify-evenly">
                <Link href={commonRoutes.forbes} passHref>
                  <a target="_blank" className="w-[81px] h-[30px]">
                    <Image
                      alt={intl.formatMessage({ id: 'getstarted.image-alt' })}
                      width={81}
                      height={30}
                      layout="responsive"
                      src="/images/forbes.png"
                    />
                  </a>
                </Link>

                <Link href={commonRoutes.refinery} passHref>
                  <a target="_blank" className="w-[95px] h-[30px]">
                    <Image
                      alt={intl.formatMessage({ id: 'getstarted.image-alt' })}
                      width={95}
                      height={30}
                      layout="responsive"
                      className={''}
                      src="/images/refinery.png"
                    />
                  </a>
                </Link>

                <Link href={commonRoutes.realSimple} passHref>
                  <a target="_blank" className="w-[126px] h-[30px]">
                    <Image
                      alt={intl.formatMessage({ id: 'getstarted.image-alt' })}
                      width={126}
                      height={30}
                      layout="responsive"
                      src="/images/realsimple.png"
                    />
                  </a>
                </Link>
              </div>
              <div className="flex items-center flex-shrink my-2 container-default justify-evenly">
                <Link href={commonRoutes.next} passHref>
                  <a target="_blank" className="w-[95px] h-[30px]">
                    <Image
                      alt={intl.formatMessage({ id: 'getstarted.image-alt' })}
                      width={95}
                      height={30}
                      layout="responsive"
                      src="/images/next.png"
                    />
                  </a>
                </Link>
                <Link href={commonRoutes.nationalmortgageprofessional} passHref>
                  <a target="_blank" className="w-[500px] h-[18px]">
                    <Image
                      alt={intl.formatMessage({ id: 'getstarted.image-alt' })}
                      width={500}
                      height={18}
                      layout="responsive"
                      src="/images/nationalmortgage.png"
                    />
                  </a>
                </Link>
                <Link href={commonRoutes.housingWire} passHref>
                  <a target="_blank" className="w-[30px] h-[30px]">
                    <Image
                      alt={intl.formatMessage({ id: 'getstarted.image-alt' })}
                      width={30}
                      height={30}
                      layout="responsive"
                      src="/images/hw.png"
                    />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
