import CoBorrowerDetails from 'components/co-borrower/co-borrower-details';
import { memo } from 'react';
import { DisAssociateCoBorrower } from '../../co-borrower/disassociation-consent';

const UnMemoCoBorrowerInput = () => {
  return (
    <>
      <CoBorrowerDetails />
      <DisAssociateCoBorrower />
    </>
  );
};

export const CoBorrowerInput = memo(UnMemoCoBorrowerInput);

export * from '../../co-borrower/InviteCoBorrower';
