import { useOnboarding } from '@hooks';
import ArrowLeft from '@icons/arrow-left.svg';
import ChatBubbleWarningIcon from '@icons/chat-bubble-warning.svg';
import CloseIcon from '@icons/close.svg';
import { commonRoutes } from '@utils';
import clsx from 'clsx';
import { Button } from 'components/button';
import { Form } from 'components/form';
import { Modal } from 'components/modal';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

export const FullModal = ({
  onClose,
  open,
  methods,
  onSubmit,
  modelShow = true,
  children,
  title,
  header,
  onClickBackButton,
  alwaysShowCloseButton,
  alwaysShowBackButton,
  panelClassName,
  titleClassName,
  showFeedbackButton,
  canSkip = false,
  onSkip = () => null,
}: {
  onClose: () => void;
  open: boolean;
  alwaysShowCloseButton?: boolean;
  alwaysShowBackButton?: boolean;
  methods?: any;
  onSubmit?: any;
  modelShow?: boolean;

  children: ReactNode;
  title?: string;
  header?: ReactNode;
  onClickBackButton?: () => void;
  panelClassName?: string;
  titleClassName?: string;
  showFeedbackButton?: boolean;
  canSkip?: boolean;
  onSkip?: () => void;
}) => {
  const Element = methods && onSubmit ? Form : 'div';
  const { setCurrentModal } = useOnboarding();
  const router = useRouter();
  const isOnSettings = router.pathname.includes('settings');
  return (
    <>
      {modelShow ? (
        <Modal
          open={open}
          onClose={onClose}
          wrapperClassName="flex flex-col lg:flex-row lg:min-h-[90%] min-h-[100%] lg:p-4 items-center justify-center text-center"
          panelClassName={clsx(
            'w-full lg:max-w-[480px] lg:rounded-lg text-left flex flex-col flex-grow lg:block lg:overflow-visible',
            panelClassName,
          )}
        >
          <Element
            wrapperClassName="h-full flex flex-col flex-grow "
            className="h-full flex flex-col flex-grow"
            onSubmit={onSubmit}
            methods={methods}
          >
            <div className="flex items-center py-5 lg:py-6 px-6 z-20">
              {alwaysShowBackButton ? (
                <button
                  className="z-[1]"
                  onClick={() => {
                    if (onClickBackButton) {
                      onClickBackButton();
                    } else {
                      onClose();
                    }
                  }}
                  type="button"
                >
                  <ArrowLeft />
                </button>
              ) : (
                <button
                  className="lg:hidden z-[1]"
                  onClick={() => {
                    if (onClickBackButton) {
                      onClickBackButton();
                    } else {
                      onClose();
                    }
                  }}
                  type="button"
                >
                  <ArrowLeft />
                </button>
              )}

              {showFeedbackButton ? (
                <div className="flex justify-between w-full  border border-red">
                  <p className="mx-auto lg:mx-0 w-max font-medium font-slab text-gray-900 pr-5 lg:text-xl text-center ">
                    {title}
                  </p>
                  <button
                    onClick={() => {
                      if (isOnSettings) {
                        router.push(commonRoutes.buyer.auth.feedback);
                      } else {
                        setCurrentModal('feedback');
                      }
                    }}
                    className="lg:ml-auto lg:mr-4"
                    type="button"
                  >
                    <ChatBubbleWarningIcon width={24} className="keep-color" />
                  </button>
                </div>
              ) : header ? (
                header
              ) : (
                <p className="mx-auto lg:mx-0 font-medium font-slab text-gray-900 pr-5 lg:text-xl w-full text-center">
                  {title}
                </p>
              )}

              {alwaysShowCloseButton ? (
                <button className="ml-auto" onClick={() => onClose()} type="button">
                  <CloseIcon strokeWidth={2} width={20} />
                </button>
              ) : (
                <button
                  className="lg:block hidden"
                  onClick={() => {
                    onClose();
                  }}
                  type="button"
                >
                  <CloseIcon strokeWidth={2} width={20} />
                </button>
              )}
            </div>
            {children}
          </Element>
        </Modal>
      ) : (
        <>
          <Element
            wrapperClassName="h-full flex flex-col flex-grow"
            className="h-full flex flex-col flex-grow"
            onSubmit={onSubmit}
            methods={methods}
          >
            <div className={clsx('flex items-center py-5 lg:py-6 px-2', titleClassName)}>
              {alwaysShowBackButton ? (
                <button
                  className="z-[1]"
                  onClick={() => {
                    if (onClickBackButton) {
                      onClickBackButton();
                    } else {
                      onClose();
                    }
                  }}
                  type="button"
                >
                  <ArrowLeft />
                </button>
              ) : (
                <button
                  className="lg:hidden z-[1]"
                  onClick={() => {
                    if (onClickBackButton) {
                      onClickBackButton();
                    } else {
                      onClose();
                    }
                  }}
                  type="button"
                >
                  <ArrowLeft />
                </button>
              )}

              {showFeedbackButton ? (
                <div className="flex justify-between w-full">
                  <p className="mx-auto lg:mx-0 w-max font-medium text-center font-slab text-gray-900 pr-5 lg:text-xl">
                    {title}
                  </p>
                  <button
                    onClick={() => {
                      if (isOnSettings) {
                        router.push(commonRoutes.buyer.auth.feedback);
                      } else {
                        setCurrentModal('feedback');
                      }
                    }}
                    className="lg:ml-auto lg:mr-4"
                    type="button"
                  >
                    <ChatBubbleWarningIcon width={24} className="keep-color" />
                  </button>
                </div>
              ) : header ? (
                header
              ) : (
                <div className={clsx('text-[25px] text-center w-full font-bold')}>{title} </div>
              )}
              {canSkip && (
                <Button className="lg:hidden" type="button" variant="text-primary" onClick={onSkip}>
                  skip
                </Button>
              )}
              {/* {alwaysShowCloseButton ? (
            <button className="ml-auto" onClick={() => onClose()} type="button">
              <CloseIcon strokeWidth={2} width={20} />
            </button>
          ) : (
            <button className="lg:block ml-auto hidden" onClick={() => onClose()} type="button">
              <CloseIcon strokeWidth={2} width={20} />
            </button>
          )} */}
            </div>
            {children}
          </Element>
        </>
      )}
    </>
  );
};

export const CompleteModal = ({
  onClose,
  open,
  children,
  panelClassName,
}: {
  onClose: () => void;
  open: boolean;
  children: ReactNode;
  panelClassName?: string;
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      wrapperClassName="flex flex-col lg:flex-row lg:min-h-[90%] min-h-[100%] lg:p-4 items-center justify-center text-center"
      panelClassName={clsx(
        'w-full lg:max-w-[480px] lg:rounded-lg text-left flex flex-col flex-grow lg:block lg:overflow-visible',
        panelClassName,
      )}
    >
      {children}
    </Modal>
  );
};

export const CompleteModalForm = ({
  onClose,
  methods,
  onSubmit,
  children,
  title,
  header,
  onClickBackButton,
  alwaysShowCloseButton,
  alwaysShowBackButton,
  hideBackButton,
}: {
  onClose: () => void;
  alwaysShowCloseButton?: boolean;
  alwaysShowBackButton?: boolean;
  methods?: any;
  onSubmit?: any;
  children: ReactNode;
  title?: string;
  header?: ReactNode;
  onClickBackButton?: () => void;
  hideBackButton?: boolean;
}) => {
  return (
    <Form
      wrapperClassName="h-full flex flex-col flex-grow"
      className="h-full flex flex-col flex-grow "
      onSubmit={onSubmit}
      methods={methods}
    >
      <div className="flex items-center py-5 lg:py-6 ">
        {' '}
        {/* removed code border-b border-b-gray-200 */}
        {!hideBackButton && (
          <>
            {alwaysShowBackButton ? (
              <button
                className="z-[1]"
                onClick={() => {
                  if (onClickBackButton) {
                    onClickBackButton();
                  } else {
                    onClose();
                  }
                }}
                type="button"
              >
                <ArrowLeft />
              </button>
            ) : (
              <button
                className="lg:hidden z-[1]"
                onClick={() => {
                  if (onClickBackButton) {
                    onClickBackButton();
                  } else {
                    onClose();
                  }
                }}
                type="button"
              >
                <ArrowLeft />
              </button>
            )}
          </>
        )}
        {header ? (
          header
        ) : (
          <p className="mx-auto lg:mx-0 w-max font-medium font-slab text-gray-900 pr-5 lg:text-xl">
            {title}
          </p>
        )}
        {alwaysShowCloseButton ? (
          <button className="ml-auto z-20" onClick={() => onClose()} type="button">
            <CloseIcon strokeWidth={2} width={20} />
          </button>
        ) : (
          alwaysShowCloseButton && (
            <button
              className="lg:block ml-auto z-20 hidden"
              onClick={() => onClose()}
              type="button"
            >
              <CloseIcon strokeWidth={2} width={20} />
            </button>
          )
        )}
      </div>
      {children}
    </Form>
  );
};
