import { FilterPill } from 'components';
import { useState } from 'react';
import { PastRangeOptions } from 'services/Api/controllers';

export const TransactionHistoryFilters = ({
  onSelect,
  defaultValue,
}: {
  onSelect: Function;
  defaultValue?: PastRangeOptions;
}) => {
  const [selected, setSelected] = useState<PastRangeOptions>(defaultValue || 'pastWeek');

  const handleSelect = (selectedFilter) => {
    setSelected(selectedFilter);
    onSelect(selectedFilter);
  };

  return (
    <div className="flex mb-8 w-80 justify-between">
      <FilterPill
        text="Past Week"
        active={selected === 'pastWeek'}
        onClick={() => handleSelect('pastWeek')}
      />
      <FilterPill
        text="Past Month"
        active={selected === 'pastMonth'}
        onClick={() => handleSelect('pastMonth')}
      />
      <FilterPill
        text="Past Year"
        active={selected === 'pastYear'}
        onClick={() => handleSelect('pastYear')}
      />
    </div>
  );
};
