import { Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { commonRoutes } from '@utils';

export const BackButtonComponent = () => {
  const router = useRouter();

  return (
    <Box
      className="back-button-area"
      sx={{
        backgroundColor: '#fff',
        padding: '14.4px',
        borderRadius: '14.4px',
        display: 'flex',
        gap: '14.4px',
        width: '163px',
        cursor: 'pointer',
      }}
      onClick={() => router.push(commonRoutes.getStarted)}
    >
      <Box
        display="flex"
        sx={{
          padding: '6px',
          color: '#fff',
          border: '1px solid #000',
          width: '36.48px',
          height: '36.48px',
          borderRadius: '11.52px',
        }}
      >
        <ArrowBackIcon style={{ color: 'black' }} />
      </Box>
      <Image src="/images/MHP_Lockup_Mint.png" alt="MHP logo" width="78px" height="33.6px" />
    </Box>
  );
};
