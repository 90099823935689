import Image from 'next/image';
import { ReactNode } from 'react';
import EmptyImage from '../../../public/images/empty.png';

export const Empty = ({
  main,
  flavor,
  hideImage,
}: {
  main: ReactNode;
  flavor: ReactNode;
  hideImage?: boolean;
}) => {
  return (
    <div className="text-center border border-gray-200 pt-6 -mt-2 px-4 flex flex-col items-center rounded mx-6 lg:mx-0">
      <p className="text-sm font-medium text-gray-600 mb-1.5">{main}</p>
      <p className="text-sm text-gray-600 mb-6">{flavor}</p>
      {!hideImage && (
        <div className="max-w-[400px] flex">
          <Image alt="" src={EmptyImage} />
        </div>
      )}
    </div>
  );
};
