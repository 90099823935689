import { ApiService } from 'services/Api';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export type BuyerFormFields = ApiService.SignUpBuyerBody;

export interface BuyerState extends Partial<BuyerFormFields> {
  setBuyerInfo: (state) => void;
  setBuyerResetPasswordInfo: (state) => void;
  reset: () => void;
  step: number;
  email: string;
  partnerCodeStatus: boolean;
}

const initialState = {
  email: '',
  step: 1,
  partnerCodeStatus: false,
  associateIdStatus: false,
  firstName: '',
  lastName: '',
  userType: '',
  provider: '',
  authenticationId: '',
};

export const useBuyerSignupInfo = create<BuyerState>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setBuyerInfo: (state) => set(state, false, 'buyer-signup-info/setBuyerInfo'),
        setBuyerResetPasswordInfo: (state) =>
          set(state, false, 'buyer-signup-info/setBuyerResetPasswordInfo'),
        reset: () => set(initialState, false, 'buyer-signup-info/reset'),
      }),
      { name: 'buyer-signup-info' },
    ),
    { name: 'buyer-signup-info' },
  ),
);

type BuyerSigninState = {
  authenticationId: string;
} & Pick<BuyerState, 'step' | 'setBuyerInfo' | 'email'>;
export const useBuyerSigninInfo = create(
  persist<BuyerSigninState>(
    (set) => ({
      email: '',
      authenticationId: '',
      setBuyerInfo: (state) => set(state),
      step: 1,
    }),
    { name: 'buyer-signin-info' },
  ),
);

type BuyerResetPasswordState = {
  authenticationId: string;
} & Pick<BuyerState, 'step' | 'setBuyerResetPasswordInfo' | 'email'>;
export const useBuyerResetPassword = create(
  devtools(
    persist<BuyerResetPasswordState>(
      (set) => ({
        email: '',
        authenticationId: '',
        setBuyerResetPasswordInfo: (state) => set(state),
        step: 1,
      }),
      { name: 'buyer-reset-password-info' },
    ),
    { name: 'buyer-reset-password-info' },
  ),
);
