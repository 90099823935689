import ChevronUp from '@icons/chevron-up.svg';
import { GenericModal } from 'components/modal/generic-modal';
import { useEffect, useState } from 'react';
import { MonthModalContent } from './month-modal-content';
import { Dayjs } from 'dayjs';
import clsx from 'clsx';

export const MonthPicker = ({
  maxDate,
  selectedDate,
  setSelectedDate,
}: {
  maxDate?: Dayjs;
  selectedDate: Dayjs;
  setSelectedDate: Function;
}) => {
  const [showModal, setShowModal] = useState(false);

  const [tempDate, setTempDate] = useState(maxDate);

  const handleClose = () => {
    setTempDate(selectedDate);
    setShowModal(false);
  };

  useEffect(() => {
    setTempDate(selectedDate);
  }, [selectedDate]);

  const handleSubmit = () => {
    setSelectedDate(tempDate);
    handleClose();
  };

  return (
    <>
      <div className="flex justify-between text-[#635E7D] w-60">
        <button
          onClick={() => setSelectedDate(selectedDate.subtract(1, 'month'))}
          className="hover:text-[#14121B] p-3"
        >
          <ChevronUp width={20} className="rotate-[270deg]" />
        </button>
        <button onClick={() => setShowModal(true)} className="hover:text-[#14121B]">
          {selectedDate.format('MMMM')}, {selectedDate.format('YYYY')}
        </button>
        <button
          onClick={() => setSelectedDate(selectedDate.add(1, 'month'))}
          className={clsx('hover:text-[#14121B] p-3', {
            invisible: maxDate && maxDate.isSame(selectedDate),
          })}
        >
          <ChevronUp width={20} className="rotate-90" />
        </button>
      </div>
      {showModal && (
        <GenericModal
          title="Pick a month"
          content={
            <MonthModalContent
              maxDate={maxDate}
              selectedDate={tempDate}
              onChange={(selDate) => setTempDate(selDate)}
            />
          }
          cancelActionTitle="Cancel"
          cancelAction={handleClose}
          onClose={handleClose}
          actionTitle="Submit"
          onActionClick={handleSubmit}
        />
      )}
    </>
  );
};
