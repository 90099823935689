import { useBuyerSignupInfo } from '@hooks';
import clsx from 'clsx';
import { Form, FormProps, StepHighlight } from 'components';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';

interface FormWrapperProps extends FormProps {
  children: ReactNode;
  onBackClick?: () => void;
  maxWidth?: number;
  containerClassName?: string;
  formClassName?: string;
}

export const FormWrapper = ({
  children,
  onBackClick,
  maxWidth = 600,
  containerClassName,
  formClassName,
  ...formProps
}: FormWrapperProps) => {
  const { step } = useBuyerSignupInfo();
  const router = useRouter();
  const isSignup = router.pathname === '/user/sign-up';
  const shouldShowSteps = step >= 3 && isSignup;

  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return (
    <div className="flex flex-col flex-grow">
      <div className={'lg:hidden absolute top-[50px] left-[50px] mb-20 '}>
        {shouldShowSteps && (
          <ul className="flex items-center gap-2 w-full justify-center pr-[80px]">
            <StepHighlight isActive={step === 3} />
            <StepHighlight isActive={step === 4} />
            <StepHighlight isActive={step === 5} />
            <StepHighlight isActive={step === 6} />
            <StepHighlight isActive={step === 7} />
          </ul>
        )}
      </div>
      <div className={clsx('container-default h-full flex-grow flex flex-col', containerClassName)}>
        <div
          className="flex flex-col flex-grow w-full lg:mx-auto "
          style={{
            //  maxWidth: isDesktop ?
            maxWidth: '624px',
          }}
        >
          <div className="flex mt-10 lg:mt-6 mb-[46px] xs:mb-0 xs:mt-10">
            {/* //Commenting out the button for now, I don't think it's used for either desktop or mobile designs  */}
            {/* <button
              className="lg:hidden z-[1]"
              onClick={() => {
                onBackClick();
              }}
              type="button"
            >
              <ArrowLeft />
            </button>  */}

            {shouldShowSteps && (
              <ul className="flex items-center justify-center w-full gap-2">
                <StepHighlight isActive={step === 3} />
                <StepHighlight isActive={step === 4} />
                <StepHighlight isActive={step === 5} />
                <StepHighlight isActive={step === 6} />
                <StepHighlight isActive={step === 7} />
              </ul>
            )}
          </div>
          <Form
            wrapperClassName="h-full flex flex-col flex-grow"
            className={clsx('flex flex-col flex-grow', formClassName)}
            {...formProps}
          >
            {children}
          </Form>
        </div>
      </div>
    </div>
  );
};
