import { useQuery } from 'react-query';
import { ApiService } from 'services/Api';
import dayjs from 'dayjs';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      border: {
        display: false,
      },
      ticks: {
        maxTicksLimit: 5,
        callback: (val) => (val !== 0 && val % 1000 === 0 ? `${val / 1000}k` : val),
      },
    },
  },
};

export const IncomeExpensesChart = () => {
  const { data: incomeExpensesChartData } = useQuery(
    ['buyer-plaid-items-income-expenses-range'],
    async () => {
      const date = dayjs();

      const resp = await ApiService.getIncomeExpensesRange(
        date.subtract(6, 'months').format('YYYY-MM-DD'),
        date.subtract(1, 'months').format('YYYY-MM-DD'),
      );

      const incomes = [];
      const expenses = [];
      const monthLabels = [];

      resp.data.forEach((incomeExpenseItem) => {
        incomes.push(incomeExpenseItem.income);
        expenses.push(incomeExpenseItem.expenses * -1);
        monthLabels.push(dayjs(incomeExpenseItem.monthDate).format('MMM').toUpperCase());
      });

      return {
        labels: monthLabels,
        datasets: [
          {
            data: incomes,
            backgroundColor: '#42D1A9',
            borderRadius: 4,
          },
          {
            data: expenses,
            backgroundColor: '#FF9580',
            borderRadius: 4,
          },
        ],
      };
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <div>{incomeExpensesChartData && <Bar options={options} data={incomeExpensesChartData} />}</div>
  );
};
