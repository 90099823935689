import { useAuthUser } from '@hooks';
import { CustomInput } from 'components/custom-input';
import { Form } from 'components/form';
import { useForm } from 'react-hook-form';

const CoBorrowerDetails = () => {
  const { profile } = useAuthUser();

  const methods = useForm<{
    coBorrowersFirstName: string;
    coBorrowersLastName: string;
    coBorrowersEmail: string;
  }>({
    defaultValues: {
      coBorrowersEmail: profile.coBorrowersEmail || profile.coBorrowerInvite.coBorrowersEmail || '',
      coBorrowersFirstName:
        profile.coBorrowersFirstName || profile.coBorrowerInvite.coBorrowersFirstName || '',
      coBorrowersLastName:
        profile.coBorrowersLastName || profile.coBorrowerInvite.coBorrowersLastName || '',
    },
  });
  return (
    <>
      <Form id="co-borrowers-details" methods={methods}>
        <CustomInput.Input
          className="mb-4 w-full"
          disabled
          label="Co-Borrower's  First Name"
          name="coBorrowersFirstName"
        />
        <CustomInput.Input
          className="mb-4 w-full"
          disabled
          label="Co-Borrower's Last Name"
          name="coBorrowersLastName"
        />
        <CustomInput.Email
          className="mb-4 w-full"
          disabled
          label="Co-Borrower's Email"
          name="coBorrowersEmail"
        />
      </Form>

    </>
  );
};

export default CoBorrowerDetails;
