import clsx from 'clsx';
import { ErrorMessage } from 'components';
import { useFormContext } from 'react-hook-form';
import { DetailedHTMLProps, ReactNode } from 'react';
import { InputHTMLAttributes } from 'react';

export const RadioWrapper = ({
  children,
  className,
  name,
}: {
  children: ReactNode;
  className?: string;
  name: string;
}) => {
  const methods = useFormContext();
  return (
    <div className={clsx('flex flex-col gap-5', className)}>
      {children}
      {methods.formState.errors[name]?.message && (
        <ErrorMessage className="-mt-2" error={methods.formState.errors[name]?.message as string} />
      )}
    </div>
  );
};

export const Radio = ({
  label,
  validations,
  description,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  validations?: Record<string, unknown>;
  description?: string;
}) => {
  const { register, watch, setValue } = useFormContext();
  const value = watch(props.name);
  const isChecked = value === props.value;
  return (
    <label
      className={clsx('flex gap-3 text-gray-800 dark:text-gray-100 cursor-pointer', {
        'items-start': description,
        'items-center': !description,
      })}
      key={props.name}
    >
      <input
        className="rounded-full border-2 border-gray-300 text-primary cursor-pointer w-[20px] h-[20px] sr-only shrink-0"
        type="radio"
        name={props.name}
        {...props}
        {...register(props.name, {
          validate: (value) =>
            value?.length === 0 ? (validations?.required as string) : undefined,
        })}
        onChange={() => {
          setValue(props.name, props.value, {
            shouldTouch: true,
            shouldValidate: true,
            shouldDirty: true,
          });
        }}
        defaultChecked={isChecked}
      />
      <div
        className={clsx(
          'rounded-full shrink-0 flex items-center justify-center w-[20px] h-[20px]',
          {
            'border-2 border-primary': isChecked,
            'border-2': !isChecked,
          },
        )}
      >
        {isChecked && <div className="w-[10px] h-[10px] bg-primary rounded-full m-auto"></div>}
      </div>
      <div>
        <div className="text-sm font-medium first-letter:uppercase">{label}</div>
        {description && <div className="mt-1 text-xs text-gray-500">{description}</div>}
      </div>
    </label>
  );
};

export const ControlledRadio = ({
  label,
  ...props
}: { label: string } & DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>) => {
  return (
    <label className={clsx('flex gap-3 dark:text-gray-100 cursor-pointer items-center')}>
      <input
        className="rounded-full border-2 border-gray-300 text-primary cursor-pointer w-[20px] h-[20px] sr-only shrink-0"
        type="radio"
        {...props}
      />
      <div
        className={clsx(
          'rounded-full shrink-0 flex items-center justify-center w-[20px] h-[20px]',
          {
            'border-2 border-[#1DAB37]': props.checked,
            'border-2 border-[#D0CAE9]': !props.checked,
          },
        )}
      >
        {props.checked && <div className="w-[10px] h-[10px] bg-primary rounded-full m-auto"></div>}
      </div>
      <div>
        <div className="text-sm font-normal first-letter:uppercase">{label}</div>
      </div>
    </label>
  );
};
