import { UseMutationOptions, useMutation } from 'react-query';
import {
  ApiService,
  BuyerOnboardingStatus,
  ChildrenUnder18CountOptions,
  EGender,
  EMilitaryStatus,
  EOnboardingStepStatus,
  EReasonForPurchase,
  WishedLocation,
} from 'services/Api';
import {
  RefreshBuyerOnboardingStatusBody,
  refreshBuyerOnboardingStatus,
} from 'services/Api/controllers';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export interface CompleteProfileFields {
  firstName: string;
  lastName: string;
  phone: string;
  birthMonth: string;
  birthDay: string;
  birthYear: string;
  gender: EGender;
  race: string;
  hispanicOrigin: 'yes' | 'no';
  children: 'yes' | 'no';
  childrenUnder18Count: ChildrenUnder18CountOptions;
  city: string;
  apartment: string;
  state: string;
  zipCode: string;
  occupation: string;
  militaryStatus: EMilitaryStatus;
  reasonForPurchase: EReasonForPurchase;
  wishedLocations: WishedLocation | any;
  expectedPurchaseMonthsPeriod: number;
  housePriceGoal: number;
  street: string;
  avatar: string;
  coBorrower: 'yes' | 'no';
  coBorrowersFirstName: string;
  coBorrowersLastName: string;
  coBorrowersEmail: string;
  houseRent: string;
  email: string;
  allowedEmails: string[];
  allowedSMS: string[];
  onboardingProfileStatus?: EOnboardingStepStatus;
}

export type CompleteModalType =
  | 'completeProfile'
  | 'addTransactionItem'
  | 'generateCreditReport'
  | 'feedback'
  | 'createCreditReportUser'
  | 'successFeedback'
  | 'addIncome';

export const useCompleteModal = create(
  devtools(
    persist<
      {
        currentModal: CompleteModalType;
        setCurrentModal: (currentModal: CompleteModalType) => void;
        setCurrentStep: (step: number) => void;
        setValues: (values: CompleteProfileFields) => void;
        reset: () => void;
        updateUserData: () => void;
        step: number;
      } & CompleteProfileFields
    >(
      (set, get) => ({
        currentModal: null,
        step: 1,
        setCurrentModal: (currentModal: CompleteModalType) => set({ currentModal }),
        setCurrentStep: (step: number) => set({ step }),
        setValues: (values: Partial<CompleteProfileFields>) => set(values, false),
        firstName: undefined,
        lastName: undefined,
        phone: undefined,
        birthMonth: undefined,
        birthDay: undefined,
        birthYear: undefined,
        children: undefined,
        childrenUnder18Count: null,
        street: undefined,
        apartment: undefined,
        city: undefined,
        state: undefined,
        zipCode: undefined,
        militaryStatus: undefined,
        occupation: undefined,
        reasonForPurchase: undefined,
        wishedLocations: undefined,
        expectedPurchaseMonthsPeriod: undefined,
        housePriceGoal: undefined,
        gender: undefined,
        race: undefined,
        hispanicOrigin: undefined,
        ssn: undefined,
        avatar: undefined,
        coBorrower: 'no',
        coBorrowersFirstName: undefined,
        coBorrowersLastName: undefined,
        coBorrowersEmail: undefined,
        houseRent: undefined,
        email: undefined,
        allowedEmails: ['recommendations', 'account_setup', 'marketing'],
        allowedSMS: [],
        reset: () => {
          set({
            currentModal: null,
            firstName: undefined,
            lastName: undefined,
            phone: undefined,
            birthMonth: undefined,
            birthDay: undefined,
            birthYear: undefined,
            children: undefined,
            childrenUnder18Count: null,
            street: undefined,
            city: undefined,
            state: undefined,
            zipCode: undefined,
            militaryStatus: undefined,
            occupation: undefined,
            reasonForPurchase: undefined,
            wishedLocations: undefined,
            expectedPurchaseMonthsPeriod: undefined,
            housePriceGoal: undefined,
            gender: undefined,
            race: undefined,
            hispanicOrigin: undefined,
            avatar: undefined,
            step: 1,
            coBorrower: 'no',
            coBorrowersFirstName: undefined,
            coBorrowersLastName: undefined,
            coBorrowersEmail: undefined,
            houseRent: undefined,
            email: undefined,
            allowedEmails: ['recommendations', 'account_setup', 'marketing'],
            allowedSMS: [],
          });
        },
        updateUserData: async () => {
          const data = Object.fromEntries(
            Object.entries(JSON.parse(JSON.stringify({ ...get() })))
              .map(([key, value]) => {
                if (!value) return null;
                if (['yes', 'no'].includes(value as string)) {
                  value = value === 'yes' ? true : false;
                }
                return [key, value];
              })
              .filter((e) => e),
          );
          const res = await ApiService.updateBuyer(data);
        },
      }),
      { name: 'mhp-complete-modal' },
    ),
    { name: 'mhp-complete-modal' },
  ),
);

export const useSkipOnboardingSteps = () => {
  const skipMutation = useMutation(async (body: RefreshBuyerOnboardingStatusBody) => {
    const { data } = await refreshBuyerOnboardingStatus(body);
    return data;
  });
  return {
    skip: (
      body: RefreshBuyerOnboardingStatusBody,
      options?: Omit<
        UseMutationOptions<
          BuyerOnboardingStatus,
          unknown,
          RefreshBuyerOnboardingStatusBody,
          unknown
        >,
        'mutationFn'
      >,
    ) => skipMutation.mutate(body, options),
    skipMutation,
  };
};
