import React, { useCallback, useEffect, useState } from 'react';
import { DashboardInfoModal } from '../dashboard-info-modal';
import { Button } from 'components/button';
import { AvatarUpload } from 'components/avatar-upload';
import { useFormContext } from 'react-hook-form';
import { useAuthUser } from '@hooks';

export const UploadImage = () => {
  const [open, setOpen] = useState(false);
  const [url, setURL] = useState('');
  const methods = useFormContext();
  const { profile } = useAuthUser();

  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);

  const onUpload = useCallback((url: string) => setURL(url), [setURL]);

  const onSave = () => {
    methods.setValue('avatar', url, { shouldTouch: true, shouldDirty: true });
    methods.trigger('avatar');
    onClose();
  };

  return (
    <>
      <div onClick={onOpen} className="text-primary-500 cursor-pointer uppercase">
        {profile?.avatar ? `Change` : `Upload`} Picture
      </div>
      <DashboardInfoModal title="Upload Picture" open={open} onClose={onClose}>
        <div className="flex flex-col justify-between items-center min-h-full">
          <div className="w-56 ml-auto mr-auto mt-10 border-1 border-red flex flex-col justify-center items-center ">
            <AvatarUpload onFileUpload={onUpload} />
            <div className="text-black-100 text-xs mt-2 ml-6">Upload a file size of max 5MB</div>
          </div>
          <Button onClick={onSave} className=" w-96 m-auto mt-12 " disabled={!!!url}>
            Save
          </Button>
        </div>
      </DashboardInfoModal>
    </>
  );
};
