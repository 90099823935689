import clsx from 'clsx';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ListItemRecord } from 'services/Api';
import CloseIcon from '@icons/close.svg';

interface SuggestedSkillsProps {
  suggestedSkills: ListItemRecord[];
  fieldName?: string;
}

export const SuggestedSkills = ({
  suggestedSkills,
  fieldName = 'skills',
}: SuggestedSkillsProps) => {
  const [isSuggestedSkillsOpen, setIsSuggestedSkillsOpen] = useState(true);
  const methods = useFormContext();
  const selectedSkills = methods.watch(fieldName);

  return (
    <>
      {isSuggestedSkillsOpen && suggestedSkills?.length > 0 && (
        <div>
          <div className="mt-8 p-4 rounded-lg border border-gray-200 bg-gray-50 dark:border-black-dark dark:bg-transparent">
            <div className="flex justify-between mb-3">
              <p className="text-sm font-medium">
                Suggested based on your focus
              </p>{' '}
              <button
                onClick={() => {
                  setIsSuggestedSkillsOpen(false);
                }}
                type="button"
              >
                <CloseIcon height={16} strokeWidth={2} />
              </button>
            </div>
            <div className="flex gap-2 flex-wrap">
              {suggestedSkills.map((suggestedSkill) => {
                const isActive = selectedSkills.some(
                  (skill) => skill.value === suggestedSkill.value
                );

                return (
                  <button
                    onClick={() => {
                      methods.setValue(
                        fieldName,
                        isActive
                          ? selectedSkills.filter(
                              (skill) => skill.value !== suggestedSkill.value
                            )
                          : [
                              ...selectedSkills,
                              {
                                label: suggestedSkill.label,
                                value: suggestedSkill.value,
                              },
                            ],
                        {
                          shouldDirty: true,
                          shouldValidate: true,
                        }
                      );
                    }}
                    type="button"
                    key={suggestedSkill.value}
                    disabled={isActive}
                    className={clsx(
                      'px-4 py-2 border  rounded-full text-sm font-medium flex items-center gap-2 dark:hover:text-white disabled:hover:bg-transparent min-h-[40px] flex-wrap  disabled:border-gray-400 disabled:text-gray-400 text-gray-600 dark:text-gray-400 dark:border-gray-400 dark:disabled:text-gray-500 dark:disabled:border-gray-500 dark:hover:bg-gray-900 dark:hover:disabled:bg-transparent hover:bg-gray-600 hover:border-gray-600 hover:text-white border-gray-600'
                    )}
                  >
                    <div className="-mt-[1px]">{suggestedSkill.label}</div>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
