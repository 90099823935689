import { countryPhoneCodes, extractNumbers, validEmail, validName, validUserName } from '@utils';
import { AxiosRequestConfig } from 'axios';
import clsx from 'clsx';
import { ErrorMessage } from 'components/error-message';
import { getCountryCallingCode, isValidPhoneNumber } from 'libphonenumber-js';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import en from 'react-phone-number-input/locale/en.json';
// @ts-ignore
import PhoneInput from 'react-phone-input-2';
import { useMemo } from 'react';

const styles = {
  input_title: 'text-xs lg:text-sm text-black-russian mb-3 dark:text-white',
  input:
    'h-[40px] lg:h-[46px] border border-gray dark:border-black-dark w-full px-2 rounded-[4px] bg-transparent text-sm',
};

interface InputProps {
  name: string;
  validations: any;
  responseError: AxiosRequestConfig;
}

export const Input = ({ name, validations, responseError }: InputProps) => {
  const intl = useIntl();
  const {
    formState: { errors, isSubmitted },
    register,
  } = useFormContext();

  const formatValidations = { ...validations };

  const label = intl.formatMessage({ id: `page.account.${name}` });

  if (!validations?.pattern?.value) {
    formatValidations.pattern = {
      value: validations?.pattern,
      message: `This ${label.toLowerCase()} is invalid, please try again.`,
    };
  }

  if (validations?.required) {
    formatValidations.required = `${label.toLowerCase()} is required.`;
  }

  return (
    <div className="flex flex-col">
      <label htmlFor={name} className={clsx(styles.input_title)}>
        {label}
      </label>
      <input className={clsx(styles.input)} type="text" {...register(name, formatValidations)} />
      {errors && errors[name]?.message && isSubmitted ? (
        <ErrorMessage error={errors[name]?.message as string} className="text-xs" />
      ) : (
        responseError?.data?.error === 'fields_already_in_use' &&
        responseError?.data?.metadata?.fields?.includes(name.toLowerCase()) && (
          <ErrorMessage error={`This ${name} is already taken. Please try again.`} />
        )
      )}
    </div>
  );
};

type CustomInput = Omit<InputProps, 'validations' | 'name'> & {
  name?: string;
  validations?: any;
};

export const InputEmail = (props: CustomInput) => {
  return <Input name="email" validations={{ required: true, pattern: validEmail }} {...props} />;
};

export const InputName = (props: CustomInput) => {
  return (
    <Input
      name="name"
      validations={{
        required: true,
        pattern: validName,
      }}
      {...props}
    />
  );
};

export const InputUserName = (props: CustomInput) => {
  return (
    <Input
      name="userName"
      validations={{
        required: true,
        pattern: {
          value: validUserName,
          message: 'Username can only contain alphanumeric characters (A-Z or 0-9).',
        },
        minLength: {
          value: 3,
          message: 'Username must contain at least 3 characters.',
        },
      }}
      {...props}
    />
  );
};

interface InputPhoneProps {
  responseError?: AxiosRequestConfig;
  resetError?: () => void;
  showLabel?: boolean;
}

export const InputPhone = ({ responseError, resetError, showLabel }: InputPhoneProps) => {
  const countryCodes = useMemo(() => countryPhoneCodes(), []);
  const intl = useIntl();
  const {
    setValue,
    watch,
    register,
    formState: { errors, isSubmitted },
  } = useFormContext();

  const phone = watch('phone');
  const countryCode = watch('countryCode');

  const registerPhone = register('phone', {
    required: 'Phone is required.',
    validate: (phone) => {
      const dialCode = getCountryCallingCode(countryCode);

      const purePhone = `+${dialCode}${extractNumbers(phone)}`;

      if (purePhone === `+${dialCode}`) {
        return 'Phone number is required.';
      } else if (!isValidPhoneNumber(purePhone)) {
        return 'Invalid phone number.';
      }

      if (resetError) {
        resetError();
      }
    },
  });
  return (
    <div>
      {showLabel && <p className={clsx(styles.input_title)}>Phone number</p>}
      <div className="grid w-full grid-cols-1 border divide-y rounded border-gray divide-gray dark:border-black-dark dark:divide-black-dark">
        <div className="px-4 py-1 lg:py-2">
          <label className="text-[10px] leading-5 text-gray-card flex -mb-1 lg:-mb-[2px]">
            {intl.formatMessage({ id: 'cmpt.phone-input.country' })}
          </label>
          <select
            value={countryCode}
            {...register('countryCode', {
              onChange: () => {
                setValue('phone', '');
              },
            })}
            className="w-full text-[11px] lg:text-sm p-0 text-sm border-0 border-white border-none outline-none text-gray-900 focus:outline-none bg-transparent dark:text-white cursor-pointer"
          >
            {countryCodes.map((country) => (
              <option value={country} key={country}>
                {en[country]} (+{getCountryCallingCode(country)})
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col px-4 py-1 lg:py-2">
          <label
            htmlFor="phone"
            className="text-sm leading-5 text-gray-card !text-[10px] flex -mb-1 lg:mb-0"
          >
            {intl.formatMessage({ id: 'cmpt.phone-input.number' })}
          </label>
          <div key={countryCode} className={clsx('text-gray-900 dark:text-white')}>
            <PhoneInput
              onlyCountries={[countryCode.toLowerCase()]}
              // @ts-ignore although the typings say this prop doesn't exists, it really does and is necessary to make this input work
              defaultCountry={countryCode.toLowerCase()}
              value={phone}
              {...registerPhone}
              onChange={(phone) => {
                registerPhone.onChange({
                  target: { value: phone, name: 'phone' },
                });
              }}
              specialLabel=""
              disableCountryCode
              disableCountryGuess
              jumpCursorToEnd={false}
              inputClass="w-full p-0 text-sm border-0 border-none outline-none focus:border-0 text-[11px] lg:text-sm bg-transparent"
              placeholder="Enter a phone number"
            />
          </div>
        </div>
      </div>

      {errors && errors.phone?.message && isSubmitted ? (
        <ErrorMessage error={errors.phone?.message as string} className="text-xs" />
      ) : (
        responseError?.data?.error === 'fields_already_in_use' &&
        responseError?.data?.metadata.fields.includes('phone') && (
          <ErrorMessage
            error={
              responseError?.data?.error === 'fields_already_in_use' &&
              responseError?.data?.metadata.fields.includes('phone') &&
              'This phone already exists, please try again.'
            }
          />
        )
      )}
    </div>
  );
};
