import { useFormContext } from 'react-hook-form';
import { ChildrenUnder18CountOptions } from 'services/Api';
import { Select } from '../select';

export const ChildrenSelect = (props) => {
  const methods = useFormContext();

  const currentChildren = methods.watch('children');

  const disabled = !currentChildren || currentChildren === 'no';

  return (
    <div className="grid grid-rows-2 gap-4">
      <Select
        name="children"
        options={[
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ]}
        label="Have Children"
        defaultValue="no"
        {...props}
        onChange={() => {
          methods.setValue('childrenUnder18Count', null, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        }}
      />
      <Select
        disabled={disabled}
        name="childrenUnder18Count"
        options={[
          { label: '0-2', value: ChildrenUnder18CountOptions.zeroTwo },
          { label: '3-5', value: ChildrenUnder18CountOptions.threeFive },
          { label: '6-10', value: ChildrenUnder18CountOptions.sixTen },
          { label: 'More than 10', value: ChildrenUnder18CountOptions.moreThanTen },
        ]}
        label="Number Of Children under 18"
        showRequired={false}
        validations={{
          validate: (value) => {
            if (methods.getValues('children') === 'yes' && !value) {
              return 'Children under 18 is required.';
            }
            return undefined;
          },
        }}
      />
    </div>
  );
};
