import clsx from 'clsx';
import { ListItemRecord } from 'services/Api';

interface SkillPills {
  skills: ListItemRecord[];
  scrollSkills?: boolean;
}

export const SkillPills = ({ skills, scrollSkills = true }: SkillPills) => {
  return (
    <ul
      className={clsx('flex gap-2 pb-4 px-6 lg:pb-0 lg:px-0', {
        'overflow-auto': scrollSkills,
        'flex-col': !scrollSkills,
      })}
    >
      {skills.map((skill) => (
        <li
          key={skill.value + skill.label}
          className={clsx(
            'rounded-full py-2 px-4 border text-gray-800 border-gray-800 font-medium text-sm w-max first-letter:capitalize',
            {
              'whitespace-nowrap': scrollSkills,
              truncate: !scrollSkills,
            },
          )}
        >
          {skill.label}
        </li>
      ))}
    </ul>
  );
};
