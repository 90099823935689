import { Modal, Button, ErrorMessage, InputEmail, Form, InputPhone } from '@components';
import { useIntl } from 'react-intl';
import { E164Number, CountryCode } from 'libphonenumber-js';
import { getCompletePhone } from '@utils';
import { AxiosRequestConfig } from 'axios';
import { useForm } from 'react-hook-form';
import { useAuthUser } from '@hooks';

export interface SendCodeModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (phone: E164Number | undefined) => void;
  error?: AxiosRequestConfig<any> | null | undefined;
}

export const SendCodeModal = ({
  open,
  onClose,
  onSubmit: parentOnSubmit,
  error,
}: SendCodeModalProps) => {
  const intl = useIntl();

  const { selectedAuth } = useAuthUser();

  const methods = useForm<{
    email?: string;
    phone?: string;
    countryCode?: CountryCode;
  }>({
    defaultValues: {
      countryCode: 'CA',
    },
  });
  const { watch, handleSubmit } = methods;
  const email = watch('email');
  const phone = watch('phone');
  const countryCode = watch('countryCode');

  const onSubmit = () => {
    if (selectedAuth === 'phone') {
      parentOnSubmit(getCompletePhone({ countryCode, phone }));
    } else {
      parentOnSubmit(email);
    }
  };

  return (
    <Modal
      open={open}
      wrapperClassName="flex min-h-[90%] items-center justify-center p-4 text-center"
      panelClassName="w-full max-w-xl rounded-2xl !p-6 lg:!py-10 lg:!px-8 text-left"
      onClose={() => {
        onClose();
      }}
      title={
        <div className="flex flex-wrap items-center justify-between gap-1">
          <div className="text-sm lg:text-2xl">
            {intl.formatMessage({ id: 'cmpt.phone-input.title' })}
          </div>{' '}
        </div>
      }
    >
      <Form methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <p className="mt-3 mb-4 text-xs !leading-5 text-black lg:mt-3 lg:mb-4 lg:text-sm dark:text-black-gray">
          {intl.formatMessage({
            id: selectedAuth === 'phone' ? 'cmpt.phone-input.narrator' : 'cmpt.email.narrator',
          })}
        </p>
        {selectedAuth === 'phone' && <InputPhone responseError={error} />}
        {selectedAuth === 'email' && <InputEmail responseError={error} />}
        <p className="mt-[10px] mb-4 text-[10px] lg:text-xs text-gray-900 dark:text-black-gray">
          {intl.formatMessage({
            id:
              selectedAuth === 'email' ? 'cmpt.email.description' : 'cmpt.phone-input.description',
          })}{' '}
          <a
            href="/user/"
            target="_blank"
            className="text-[10px] lg:text-xs text-blue-ribbon-300"
            rel="noreferrer"
          >
            {intl.formatMessage({ id: 'general.privacy-policy' })}
          </a>
        </p>
        {error && <ErrorMessage className="mb-3" error={error?.data?.message} />}
        <Button className="!h-auto py-2" type="submit">
          <p className="text-[10px] lg:text-lg">
            {intl.formatMessage({ id: 'cmpt.phone-input.button' })}
          </p>
        </Button>
      </Form>
    </Modal>
  );
};
