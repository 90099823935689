import { Loading } from '@components';
import { BuyerFormFields, useBuyerSignupInfo } from '@hooks';
import { commonRoutes, localStorageDataRef } from '@utils';
import { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useCallback, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import { ApiService, EUserType } from 'services/Api';
import { Step1 } from './step1';
import { Step2 } from './step2';
import { Step3 } from './step3';

interface Props {
  onSubmit: (values: ApiService.SignUpBuyerBody) => void;
}

export interface BuyerStepProps {
  onNextStep?: (values: Partial<BuyerFormFields>) => void;
  onSubmit?: (values: Partial<BuyerFormFields>) => void;
  onBeforeSubmit?: (values: Partial<BuyerFormFields>) => void;
}

export const BuyerSignupForm: FC<Props> = ({ onSubmit }) => {
  const { authenticationId, email, setBuyerInfo, reset, ...rest } = useBuyerSignupInfo();
  const [currStep, setCurrStep] = useState(1);

  const router = useRouter();
  const handleSubmit = useCallback(
    (values: BuyerFormFields) => {
      let partnerCode = null;
      if (values.accessCode && values.accessCode.length > 0) {
        partnerCode = values?.partnerCode;
      }
      const valuesToSubmit: ApiService.SignUpBuyerBody = {
        authenticationId: values.authenticationId,
        firstName: values.firstName || 'New',
        lastName: values.lastName || 'User',
        email: values.email,
        password: values.password,
        accessCode: values.partnerCode ?? undefined,
        partnerCode: values.partnerCode ?? undefined,
        lenderCode: values.lenderCode ?? undefined,
        associateId: values.associateIdStatus ? values.associateId : undefined,
        housePriceGoal: 0,
        expectedPurchaseMonthsPeriod: 0,
        // phone: Number(extractNumbers(values.phone)) > 1 ? values.phone : null,
        wishedLocations: [],
        // acceptPhoneConsent: values.acceptPhoneConsent,
        // declinePhoneConsent: values.declinePhoneConsent,
        provider: values.provider,
        inviteId: localStorage.getItem(localStorageDataRef.acceptInvite),
      } as any;

      onSubmit(valuesToSubmit);
    },
    [onSubmit],
  );

  const onNextStepMutation = useMutation<
    Partial<BuyerFormFields>,
    AxiosRequestConfig,
    Partial<BuyerFormFields>
  >(
    async ({ ...values }: Partial<BuyerFormFields>) => {
      let partnerCodeStatus: boolean = rest?.partnerCodeStatus;
      let associateIdStatus: boolean = rest?.associateIdStatus;
      // TODO: clean this up
      if (partnerCodeStatus) {
        await ApiService.savePartialSignUpForm({
          authenticationId,
          email,
          userType: EUserType.buyer,
          partialSignupForm: {
            values: {
              ...rest,
              ...values,
              partnerCodeStatus: partnerCodeStatus,
              associateIdStatus: associateIdStatus,
              email,
            } as any,
          },
        });
      } else if (associateIdStatus) {
        await ApiService.savePartialSignUpForm({
          authenticationId,
          email,
          userType: EUserType.buyer,
          partialSignupForm: {
            values: {
              ...rest,
              ...values,
              partnerCodeStatus: partnerCodeStatus,
              associateIdStatus: associateIdStatus,
              email,
            } as any,
          },
        });
      } else {
        await ApiService.savePartialSignUpForm({
          authenticationId,
          email,
          userType: EUserType.buyer,
          partialSignupForm: {
            values: {
              ...rest,
              ...values,
              partnerCodeStatus: partnerCodeStatus,
              associateIdStatus: associateIdStatus,
              email,
            } as any,
          },
        });
      }

      return { ...values, partnerCodeStatus, associateIdStatus };
    },
    {
      onSuccess: (values) => {
        const inviteId = localStorage.getItem(localStorageDataRef.acceptInvite);
        const inviteDate = localStorage.getItem(localStorageDataRef.acceptInviteDate);
        let inviteValid = false;
        if (inviteId && inviteDate) {
          const diff = dayjs(Date.now()).diff(new Date(inviteDate), 'days');
          if (diff > 2) inviteValid = false;
          else inviteValid = true;
        }
        if (values?.partnerCodeStatus || values?.associateIdStatus || inviteValid) {
          setBuyerInfo(values);
          setCurrStep((prevStep) => prevStep + 1);
        } else {
          reset();
          router.push({
            pathname: commonRoutes.buyer.getStarted,
            query: { partnerCodeStatus: false },
          });
        }
      },
      onError: (error: AxiosRequestConfig) => {
        console.error(error);
        if (error.data?.error === 'phone_number_already_exists') {
          toast.error('Phone number already in use, please try using a different one');
          return;
        }

        if (error.data?.error === 'email_already_exists') {
          toast.error('Email already in use, please try using a different one');
          return;
        }

        toast.error(`${error.data.message} - Try starting over`);
      },
    },
  );

  const toThirdStep = useCallback(
    (values) => {
      onNextStepMutation.mutate(values);
    },
    [onNextStepMutation],
  );

  return (
    <div className="flex h-full bg-center bg-no-repeat bg-cover">
      <Loading isLoading={onNextStepMutation.isLoading} />
      <div className="absolute inset-x-0 flex items-center justify-center text-center top-10">
        <Link href={commonRoutes.buyer.getStarted}>
          <a>
            <Image
              src="https://mhp-icons.s3.amazonaws.com/white-lockup.png"
              alt="Logo"
              width={130}
              height={80}
              objectFit="contain"
            />
          </a>
        </Link>
      </div>
      <div className="flex flex-col flex-grow w-full shrink-1">
        <div className="flex items-center justify-center xs:px-0">
          <div className="flex flex-col h-3/4">
            <Step
              step={currStep}
              toSecondStep={() => setCurrStep((prevStep) => prevStep + 1)}
              skipToThirdStep={() => setCurrStep(3)}
              toThirdStep={toThirdStep}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Step: FC<
  {
    step: number;
    toSecondStep: Function;
    toThirdStep: (values: Partial<BuyerFormFields>) => void;
    skipToThirdStep: Function;
  } & BuyerStepProps
> = ({ step, toSecondStep, toThirdStep, skipToThirdStep, onSubmit }) => {
  switch (step) {
    case 1:
      return <Step1 onNextStep={toSecondStep} skipToThirdStep={skipToThirdStep} />;
    case 2:
      return <Step2 onNextStep={toThirdStep} />;
    case 3:
      return <Step3 onFinish={onSubmit} />;
    // case 4:
    //   return <Step4 onNextStep={onNextStep} />;
    // case 5:
    //   return <Step5 onNextStep={onNextStep} />;
    // case 6:
    //   return <Step6 onNextStep={onNextStep} />;
    // case 7:
    //   return <Step7 onFinish={onSubmit} />;

    default:
      return <>No step found</>;
  }
};
