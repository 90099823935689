import { Button } from 'components/button';
import { Modal, ModalProps } from 'components/modal';
import DeleteAccount from '@icons/delete-account.svg';
import { useMutation } from 'react-query';
import { Toast } from '@utils';
import { useAuthUser } from '@hooks';
import { Loading } from 'components/loading';
import { ApiService } from 'services/Api';

export const DeleteAccountModal = (props: ModalProps) => {
  const { logout } = useAuthUser();

  const deleteAccountMutation = useMutation(
    async () => {
      await ApiService.deleteBuyer();
    },
    {
      onSuccess: () => {
        Toast({
          text: 'Your account has been deleted',
        });
        logout();
      },
      onError: () => {
        Toast({
          text: 'Something went wrong, please try again later, or contact our support',
          variant: 'error',
        });
      },
    },
  );

  return (
    <>
      <Loading isLoading={deleteAccountMutation.isLoading} />
      <Modal {...props} panelClassName="w-full lg:max-w-[400px] rounded-lg py-10 px-8 text-left">
        <DeleteAccount className="keep-color" />
        <h2 className="text-2xl font-medium mt-6 mb-3">Are you sure?</h2>
        <p className="text-sm text-gray-600 mb-8">
          We realize we&apos;re not perfect, but we&apos;d like you to continue with us until we
          are. Your account and all of its data will be deleted.
        </p>

        <div className="flex flex-col gap-4">
          <Button
            onClick={() => {
              deleteAccountMutation.mutate();
            }}
            variant="red"
          >
            Delete account
          </Button>
          <Button
            type="button"
            onClick={() => {
              props.onClose();
            }}
            variant="white"
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};
