import { create } from 'zustand';
import { ApiService } from 'services/Api';
import { useQuery } from 'react-query';
import { persist } from 'zustand/middleware';
import { useIsMounted } from 'hooks/use-is-mounted';
import { useAuthUser } from 'hooks/use-auth';

const useBuyerCreditScoreStore = create(
  persist<{
    creditScore: number;
    expiresAt: string;
    setBuyerCreditScore: (creditScore: number) => void;
    getBuyerCreditScore: () => { creditScore: number; expiresAt: string };
  }>(
    (set) => ({
      creditScore: null,
      expiresAt: null,

      setBuyerCreditScore: (creditScore: number) => {
        const date = new Date();
        date.setDate(date.getDate() + 14);

        set({ creditScore, expiresAt: date.toISOString() });
      },
      getBuyerCreditScore: () => {
        const { creditScore, expiresAt } = useBuyerCreditScoreStore.getState();

        if (expiresAt && new Date(expiresAt).getTime() <= new Date().getTime()) {
          set({ creditScore: null, expiresAt: null });
          return null;
        }

        if (!creditScore) {
          return null;
        }

        return { creditScore, expiresAt };
      },
      clearBuyerCreditScore: () => {
        set({ creditScore: null, expiresAt: null });
      },
    }),
    {
      name: 'buyer-credit-score',
    },
  ),
);
export const useBuyerCreditScore = (ignoreCache = false) => {
  const isMounted = useIsMounted();
  const { profile } = useAuthUser();

  const { getBuyerCreditScore, setBuyerCreditScore, clearBuyerCreditScore } =
    useBuyerCreditScoreStore();

  const retrieveCreditReportQuery = useQuery(
    ['buyer', 'credit-report'],
    async () => {
      if (!ignoreCache) {
        const cachedCreditScore = getBuyerCreditScore();

        if (cachedCreditScore) {
          return cachedCreditScore;
        }
      }

      const resp = await ApiService.retrieveBuyerCreditReport();

      setBuyerCreditScore(resp.data.score);
      return getBuyerCreditScore();
    },
    {
      onError: async () => {
        clearBuyerCreditScore();
      },
      enabled: isMounted && profile?.isOnboarded,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  return retrieveCreditReportQuery;
};
