import { useQuery } from 'react-query';
import { ApiService } from 'services/Api';
import { MonthlySpendingItem } from './monthly-spending-item';
import { Dayjs } from 'dayjs';
import { useEffect, useRef } from 'react';
import { Loading } from 'components/loading';

// Note: so this function takes into account the way the current category
// labels are formed and their icon key associations.
// Changing one or the other might impact the icons being shown for the categories
const getMainCategoryKey = (category: string) =>
  category.includes('/')
    ? category.substring(0, category.indexOf(' /')).toLowerCase()
    : category.toLowerCase();

export const MonthlySpending = ({ selectedMonth }: { selectedMonth: Dayjs }) => {
  const mounted = useRef(false);

  const {
    data: expenses,
    isFetching,
    refetch,
  } = useQuery(
    ['buyer-plaid-items-expenses'],
    async () => {
      const resp = await ApiService.getBuyerPlaidExpenses(selectedMonth.format('YYYY-MM-DD'));

      let totalExpenses = resp.data.reduce((accum, currentValue) => accum + currentValue.amount, 0);

      return {
        totalExpenses,
        spendingByCategory: resp.data.map((expense) => ({
          ...expense,
          category: expense.category || 'Not provided',
          // we have the conditional statement here to avoid dividing by 0
          percentage: totalExpenses ? Math.round((100 * expense.amount) / totalExpenses) : 0,
          mainCategoryKey: expense.category && getMainCategoryKey(expense.category),
        })),
      };
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (mounted.current) {
      refetch();
    } else {
      mounted.current = true;
    }
  }, [selectedMonth]);

  return (
    <div>
      <div className="text-xl text-[#1C1A27] font-medium mb-8">Monthly Spending</div>
      <Loading isLoading={isFetching} />
      {expenses && expenses.spendingByCategory && (
        <>
          {expenses.spendingByCategory.length
            ? expenses.spendingByCategory.map((spending, index) => (
                <MonthlySpendingItem
                  key={`spending-${index}`}
                  category={spending.category}
                  mainCategoryKey={spending.mainCategoryKey}
                  amount={spending.amount}
                  percentage={spending.percentage}
                />
              ))
            : 'No spending found'}
        </>
      )}
    </div>
  );
};
