import { useIsMounted } from '@hooks';
import { Toast } from '@utils';
import { Button } from 'components/button';
import { Loading } from 'components/loading';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { PlaidLinkOptions, PlaidLinkOptionsWithLinkToken, usePlaidLink } from 'react-plaid-link';
import { useMutation } from 'react-query';
import { ApiService } from 'services/Api';

export const BuyerPlaidOauthPage: FC<PlaidLinkOptions> = () => {
  const [error, setError] = useState<boolean>(null);
  const [token, setToken] = useState<string>(null);
  const [redirectTo, setRedirectTo] = useState<string>(null);

  const isMounted = useIsMounted();
  const router = useRouter();

  useEffect(() => {
    if (isMounted) {
      const localStorageItem = localStorage.getItem('plaid_oauth_config');

      if (localStorageItem) {
        const { token, redirectTo } = JSON.parse(localStorageItem);
        setToken(token);
        setRedirectTo(redirectTo);
      } else {
        setError(true);
      }
    }
  }, [isMounted]);

  const exchangeTransactionsPublicTokenMutation = useMutation(
    async (body: { publicToken: string; institutionId: string }) => {
      const resp = await ApiService.exchangeBuyerTransactionsPublicToken(body);
      return resp.data;
    },
    {
      onSuccess: () => {
        Toast({
          text: 'Successfully connected with your bank! You are going to be redirected soon.',
        });
        if (typeof window !== 'undefined') {
          localStorage.removeItem('plaid_oauth_config');
          window.location.href = redirectTo;
        }
      },
    },
  );

  const onSuccess = (publicToken: string, metadata: any) => {
    exchangeTransactionsPublicTokenMutation.mutate({
      publicToken,
      institutionId: metadata.institution.institution_id,
    });
  };

  const onExit = (error: any, metadata: any) => {
    setError(true);
  };

  useEffect(() => {
    if (error) {
      Toast({
        text: 'Something went wrong. Please try again.',
        variant: 'error',
        duration: 5000,
      });
    }
  }, [error]);

  return (
    <div>
      {typeof window !== 'undefined' && token && (
        <Link
          token={token}
          receivedRedirectUri={window.location.href}
          onSuccess={onSuccess}
          onExit={onExit}
          onError={() => setError(true)}
        />
      )}
      {error && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>
            <p>Something went wrong. Please try again.</p>
            <Button onClick={() => router.push('/user/summary')}>Go back to app</Button>
          </div>
        </div>
      )}
    </div>
  );
};

const Link: FC<PlaidLinkOptionsWithLinkToken & { onError: () => void }> = ({
  onSuccess,
  onExit,
  onError,
  receivedRedirectUri,
  token,
}) => {
  const { open, ready, error } = usePlaidLink({
    token,
    receivedRedirectUri,
    onSuccess,
    onExit,
  });

  useEffect(() => {
    if (ready && token && receivedRedirectUri) {
      open();
    }
  }, [ready, token, receivedRedirectUri, open]);

  useEffect(() => {
    if (error) {
      onError();
    }
  }, [error, onError]);

  return (
    <div>
      <Loading isLoading={!ready} />
      {error && <>An error has occurred</>}
      {/*{error && <pre>{JSON.stringify(error, null, 2)}</pre>}*/}
    </div>
  );
};
