import Slider from '@mui/material/Slider';
import { useMemo } from 'react';
import { ConfigItem, ScoreResult } from 'services/Api';

const getMarkValue = (i, length) => {
  return (i / (length - 1)) * 100;
};

const colors = {
  green: '#1DAB37',
  yellow: '#FFC235',
  red: '#FF433D',
};

enum Grade {
  A = 'A',
  A_MINUS = 'A-',
  B_PLUS = 'B+',
  B = 'B',
  B_MINUS = 'B-',
  C_PLUS = 'C+',
  C = 'C',
  C_MINUS = 'C-',
  D_PLUS = 'D+',
  D = 'D',
}

export const gradeInfo = {
  [Grade.A]: {
    color: colors.green,
    status: 'mastery',
  },
  [Grade.A_MINUS]: {
    color: colors.green,
    status: 'mastery',
  },
  [Grade.B_PLUS]: {
    color: colors.green,
    status: 'ready',
  },
  [Grade.B]: {
    color: colors.green,
    status: 'ready',
  },
  [Grade.B_MINUS]: {
    color: colors.green,
    status: 'ready',
  },
  [Grade.C_PLUS]: {
    color: colors.yellow,
    status: 'improving',
  },
  [Grade.C]: {
    color: colors.yellow,
    status: 'improving',
  },
  [Grade.C_MINUS]: {
    color: colors.yellow,
    status: 'improving',
  },
  [Grade.D_PLUS]: {
    color: colors.red,
    status: 'not_ready',
  },
  [Grade.D]: {
    color: colors.red,
    status: 'not_ready',
  },
};

export interface ProgressProps extends Omit<ScoreResult, 'updatedAt'> {
  minRangeLabel?: string | number;
  maxRangeLabel?: string | number;
  config: ConfigItem[];
  fullProgress?: boolean;
  reverse?: boolean;
  valueLabel?: string | number;
  isUnavailable?: boolean;
  grayBar?: boolean;
}

export const Progress = ({
  value: valueFromProps,
  valueLabel,
  pointRangeMin,
  nextGrade,
  grade,
  minRangeLabel,
  maxRangeLabel,
  config,
  fullProgress,
  reverse,
  isUnavailable,
  grayBar,
}: ProgressProps) => {
  let min = pointRangeMin;
  let max = nextGrade?.pointRangeMin;

  if (reverse && fullProgress) {
    min = config?.[config?.length - 1]?.min;
    max = config?.[0]?.max;
  } else if (fullProgress) {
    min = config?.[0]?.min;
    max = config?.[config?.length - 1]?.min;
  }

  const value = ((valueFromProps - min) * 100) / (max - min);

  const hasMaxValue =
    (reverse ? valueFromProps <= config?.[0]?.max : nextGrade?.grade == null) && config?.length > 0;

  const marks = useMemo(() => {
    if (fullProgress) {
      return ['D', 'C', 'B', 'A'];
    }

    if (hasMaxValue) {
      return reverse
        ? [config[1]?.grade, config[0]?.grade || 'A']
        : [config[config?.length - 2]?.grade, config[config?.length - 1]?.grade];
    }

    return [grade, nextGrade?.grade];
  }, [config, fullProgress, grade, hasMaxValue, nextGrade?.grade, reverse]);

  let color = isUnavailable
    ? '#EEEEEE'
    : hasMaxValue
    ? gradeInfo['A-']?.color
    : gradeInfo[grade]?.color || '#9E9E9E';
  return (
    <div>
      <div className="h-[31px] group">
        <div className="px-[20px]">
          <Slider
            disabled
            value={value}
            marks={marks.map((mark, i, arr) => {
              return {
                label: mark,
                value: getMarkValue(i, arr.length),
              };
            })}
            sx={{
              marginBottom: 0,
              padding: '0 !important',
              '.MuiSlider-rail': {
                height: 6,
                opacity: 1,
                color: hasMaxValue ? color : grayBar ? '#DEDDE3' : '#FFFFFF',
              },
              '.MuiSlider-track': {
                color,
                height: 6,
              },
              '.MuiSlider-thumb': {
                display: 'none',
              },
              '.MuiSlider-mark': {
                display: 'none',
              },
              '.MuiSlider-markLabel.MuiSlider-markLabelActive': {
                color: isUnavailable ? 'unset' : 'white',
                background: color,
              },
              '.MuiSlider-markLabel': {
                background: hasMaxValue ? color : grayBar ? '#DEDDE3' : '#FFFFFF',
                fontWeight: '400',
                fontSize: '14px',
                borderRadius: 9999,
                width: 29,
                height: 29,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: hasMaxValue ? 'white' : '#635E7D',
                top: '-12px',
              },
            }}
          />
        </div>
        {!fullProgress && (
          <div className="mx-[14px] relative">
            <div
              style={{
                left: `${hasMaxValue ? '100' : value}%`,
                backgroundColor: color,
              }}
              className="absolute -translate-x-1/2 text-white font-medium text-sm opacity-0 invisible transition-all group-hover:opacity-100 group-hover:visible lg:group-hover:block py-2 px-3 text-left !leading-tight-[150%] bg-gray-700 rounded z-10 before -top-[70px]"
            >
              <div className="w-[18px] overflow-hidden inline-block absolute -bottom-[12px] left-1/2 -translate-x-1/2 rotate-180">
                <div
                  style={{
                    backgroundColor: color,
                  }}
                  className="h-[13px] w-[13px] bg-gray-700 rotate-45 transform origin-bottom-left"
                />
              </div>
              {valueLabel}
            </div>
          </div>
        )}
      </div>
      {minRangeLabel != null && (
        <div className="flex justify-between mt-5">
          <div className="text-left text-gray-900 text-sm font-medium">{minRangeLabel}</div>

          <div className="text-right text-gray-600 text-sm font-medium">
            {maxRangeLabel} {minRangeLabel != null && !maxRangeLabel ? 'Ready!' : ''}
          </div>
        </div>
      )}
    </div>
  );
};
