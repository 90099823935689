import { commonRoutes, isValidUrl } from '@utils';
import { AxiosResponse } from 'axios';
import { useCompleteModal } from 'hooks/use-complete-modal';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import {
  ApiService,
  BuyerProfile,
  CoBorrowerProfile,
  EApprovalStatus,
  EOnboardingPathTaken,
  EOnboardingStepStatus,
  GoogleBuyerProfile,
} from 'services/Api';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import * as gtag from 'lib/gtag';
export interface UserResponse {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  phone: string;
  isEmailVerified: boolean | null;
  isPhoneVerified: boolean | null;
  agreedToTerms: boolean | null;
}

export type UserInfo = Omit<UserResponse, 'username'> & {
  phoneNumber: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  authenticationId: string;
  accessToken: string;
  updateUserInfo: (userInfo: Partial<UserInfo>) => void;
  logout: () => void;
  signedIn: boolean;
  partnerCodeStatus: boolean;
  image?: string;
  userType?: 'buyer' | 'partner';
  selectedAuth: 'phone' | 'email' | 'google';
  dataSynced?: boolean;
  profile: BuyerProfile | GoogleBuyerProfile | null;
};

const initialUserData: Omit<UserInfo, 'updateUserInfo' | 'logout'> = {
  firstName: '',
  lastName: '',
  userName: '',
  email: '',
  phoneNumber: '',
  authenticationId: '',
  accessToken: '',
  signedIn: false,
  partnerCodeStatus: null,
  image: '',
  id: '',
  phone: '',
  isEmailVerified: null,
  isPhoneVerified: null,
  agreedToTerms: null,
  userType: 'buyer',
  selectedAuth: 'phone',
  profile: null,
  dataSynced: false,
};

export const useUserStore = create(
  devtools(
    persist<UserInfo>(
      (set) => ({
        ...initialUserData,
        logout: () => {
          // if not logged in previously send login event to ga
          gtag.event({
            action: 'logout',
            category: 'engagement',
            label: 'Logout',
          });
          gtag.removeUserId();
          localStorage.clear();
          set(initialUserData);
        },
        updateUserInfo: (store: Partial<UserInfo>) => set(store, false),
      }),
      {
        name: 'userAuth',
        partialize: (state) => {
          const { userType, ...restState } = state;
          return { ...restState };
        },
      },
    ),
    {
      name: 'user-auth',
    },
  ),
);

export const useAuthUser = (authored?: boolean) => {
  const { updateUserInfo, ...userInfo } = useUserStore();
  const router = useRouter();
  const { reset: resetCompleteForm } = useCompleteModal();

  const logout = useCallback(() => {
    resetCompleteForm();
    userInfo.logout();
  }, [resetCompleteForm, userInfo]);
  const {
    isLoading: isLoadingUserInfo,
    isSuccess: isSuccessUserInfo,
    refetch: refetchUserInfo,
    isRefetching: isRefetchingUserInfo,
  } = useQuery<{ profile: BuyerProfile }, AxiosResponse>(
    ['user'],
    async () => {
      const resp = await ApiService.getProfile();
      return resp.data;
    },
    {
      enabled: Boolean(userInfo?.accessToken),
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const { profile } = data;
        const remappedUserType = profile.userType === 'buyer' ? 'buyer' : 'partner';
        updateUserInfo({
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
          phoneNumber: profile.phone,
          userType: remappedUserType,
          signedIn: true,
          profile,
        });
        const isInitialRoute = router.pathname === commonRoutes.buyer.getStarted;
        const isSignInRoute = router.pathname === commonRoutes.buyer.signin;

        // TODO: Change this on deployment
        const isSignUpRoute = router.pathname === commonRoutes.buyer.signup;
        const isOnboardingRoute = router.pathname === commonRoutes.buyer.auth.onboarding;
        const isQuickSetupRoute = router.pathname === '/user/onboarding/quick-setup';

        const isWaitlistRoute = false;
        const hasRedirect = router.query?.redirect && isValidUrl(router.query?.redirect as string);

        if (profile?.approvalStatus === EApprovalStatus.approved) {
          if (isSignUpRoute) {
            return router.push(commonRoutes.buyer.auth.onboarding);
          } else {
            if ((isInitialRoute || isSignInRoute) && !hasRedirect) {
              return router.push(commonRoutes.buyer.auth.summary);
            } else if (hasRedirect) {
              router.push(router.query?.redirect as string);
            }
          }
        } else {
          if (profile.isOnboarded && (isSignUpRoute || isOnboardingRoute)) {
            router.push(commonRoutes.buyer.auth.summary);
          } else if (profile.onboardingPathTaken === EOnboardingPathTaken.quick_setup) {
            if (profile.onboardingProfileStatus === EOnboardingStepStatus.pending) {
              router.push('/user/onboarding/quick-setup');
            } else if (isQuickSetupRoute) {
              router.push(commonRoutes.buyer.auth.summary);
            }
          } else if (profile.onboardingPathTaken === EOnboardingPathTaken.complete_setup) {
            if (profile.onboardingProfileStatus === EOnboardingStepStatus.pending) {
              router.push(commonRoutes.buyer.onboarding.completeSetup);
            } else if (
              profile.onboardingTransactionAccountStatus === EOnboardingStepStatus.pending
            ) {
              router.push(commonRoutes.buyer.onboarding.linkBankAccount);
            } else if (profile.onboardingIncomeAccountStatus === EOnboardingStepStatus.pending) {
              router.push(commonRoutes.buyer.onboarding.verifyIncome);
            } else if (profile.onboardingCreditReportStatus === EOnboardingStepStatus.pending) {
              router.push(commonRoutes.buyer.onboarding.generateCredit);
            }
          } else if (profile.onboardingPathTaken === EOnboardingPathTaken.skipped_to_dashboard) {
            // router.push(commonRoutes.buyer.auth.summary);
          } else if (!isOnboardingRoute && !profile.isOnboarded) {
            router.push(commonRoutes.buyer.auth.onboarding);
          }
          // if (isSignUpRoute) {
          //   return setTimeout(() => router.push(commonRoutes.buyer.auth.onboarding), 3000);
          // }
        }
      },
      onError: (data) => {
        if (data?.status === 401) {
          logout();
        }
      },
    },
  );

  useEffect(() => {
    if (
      router.isReady &&
      authored &&
      !userInfo.signedIn &&
      userInfo.partnerCodeStatus !== false &&
      userInfo.partnerCodeStatus !== null
    ) {
      const isInitialRoute = router.pathname === commonRoutes.buyer.getStarted;
      if (!isInitialRoute) {
        router.push(commonRoutes.buyer.getStarted);
      }
    }
  }, [userInfo, authored, router]);

  useEffect(() => {
    if (!userInfo.signedIn) return;
    gtag.addUserId(userInfo.id);
  }, [userInfo.signedIn, userInfo.id]);

  return {
    ...userInfo,
    isLoadingUserInfo,
    isSuccessUserInfo,
    updateUserInfo,
    refetchUserInfo,
    isRefetchingUserInfo,
  };
};
