import clsx from 'clsx';
import { useRouter } from 'next/router';
import { cloneElement, MouseEventHandler, ReactNode } from 'react';
import Link from 'next/link';
import { commonRoutes } from '@utils';

export const MenuLink = ({
  children,
  href,
  icon,
  isMobile,
  toggleMenu
}: {
  children: ReactNode;
  href: string;
  icon: JSX.Element;
  isMobile?: boolean;
  toggleMenu:MouseEventHandler
}) => {
  const router = useRouter();
  const isActive = router.pathname === href;
  return (
    <li
      className={clsx('rounded flex', {
        'text-[#635E7D]': !isActive,
        'bg-[#1C1A27] text-white': isActive,
        'mb-0 p-[5px]': isMobile,
        'mb-[50px] p-[15px]': !isMobile,
      })}
    >
      <Link href={href}>
        <a
          className={clsx('flex items-center hover:opacity-50', {
            'flex-col my-auto w-[60px]': isMobile,
            'gap-3': !isMobile,
          })}
          onClick={isActive ? toggleMenu : null}
        >
          {cloneElement(icon, {
            height: 20,
            className: clsx('keep-color'),
          })}
          <div
            className={clsx({
              'text-xs text-center mt-[4px]': isMobile,
            })}
          >
            {children}
          </div>
        </a>
      </Link>
    </li>
  );
};
