import { useIsMounted, useUpdateProfile } from '@hooks';
import { CustomInput } from 'components/custom-input';
import { Form } from 'components/form';
import { FullModal } from 'components/modal/full-modal';
import { useForm } from 'react-hook-form';
import { commonRoutes, mockApi } from '@utils';
import { Loading } from 'components/loading';
import { useRouter } from 'next/router';
import { useSettingsModal } from '../settings-profile';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import Helpingout from '@icons/helping-out.svg';
import { persist } from 'zustand/middleware';
import { create } from 'zustand';
import { useMediaQuery } from 'react-responsive';
import { SettingsDesktopLayout } from '../settings-desktop-layout';
import { useMutation } from 'react-query';
import { ApiService, EUserFeedbackReason } from 'services/Api';

export const useSuccessModal = create(
  persist<{ showSuccessModal: boolean; setShowSuccessModal: (showSuccessModal: boolean) => void }>(
    (set) => ({
      showSuccessModal: false,
      setShowSuccessModal: (showSuccessModal) => set({ showSuccessModal }),
    }),
    {
      name: 'mhp-settings',
    },
  ),
);

interface FeedbackForm {
  reason: string;
  feedback: string;
}

export const FeedbackContent = ({
  onSubmit,
  isModal = true,
}: {
  onSubmit: any;
  isModal?: boolean;
}) => {
  const methods = useForm<FeedbackForm>({
    defaultValues: {
      reason: '',
      feedback: '',
    },
  });

  return (
    <Form
      onSubmit={methods.handleSubmit((values) => {
        onSubmit(values);
      })}
      methods={methods}
      className="h-full flex flex-col flex-grow"
      wrapperClassName="h-full flex flex-col flex-grow"
    >
      <div className="lg:p-0 flex-grow h-full flex flex-col">
        <div className="p-6 flex flex-col gap-6">
          <CustomInput.Select
            name="reason"
            label="Why are you reaching out?"
            options={[
              { label: 'Bug', value: EUserFeedbackReason.bug },
              { label: 'Suggestion', value: EUserFeedbackReason.comment },
              { label: 'Question', value: EUserFeedbackReason.question },
              { label: 'Comment', value: EUserFeedbackReason.comment },
            ]}
          />
          <CustomInput.TextArea
            name="feedback"
            label="Share your experience"
            placeholder="Write here..."
            maxLength={250}
            rows={4}
            validations={{ required: true }}
          />
        </div>
        {isModal ? (
          <div className="p-6 pb-12 lg:pb-6 border-t border-t-gray-300 mt-auto flex flex-col gap-4">
            <Button className="mt-auto">Send feedback</Button>
          </div>
        ) : (
          <div className="px-6">
            <Button className="mt-auto">Send feedback</Button>
          </div>
        )}
      </div>
    </Form>
  );
};

export const SettingsFeedback = () => {
  const { setSettingsModalOpen } = useSettingsModal();
  const { showSuccessModal, setShowSuccessModal } = useSuccessModal();

  const isMounted = useIsMounted();

  const router = useRouter();
  const isDesktop = useMediaQuery({ minWidth: 992 });

  const mutation = useMutation(
    async (values: { reason: EUserFeedbackReason; feedback: string }) => {
      await ApiService.sendFeedback(values.reason, values.feedback);
    },
    {
      onSuccess: () => {
        setShowSuccessModal(true);
      },
    },
  );

  const onSubmit = (values) => {
    mutation.mutate(values);
  };

  return (
    isMounted && (
      <>
        <Loading isLoading={mutation.isLoading} />
        {!isDesktop && (
          <FullModal
            onClose={() => {
              setSettingsModalOpen(null);

              router.push(commonRoutes.buyer.auth.settings);
            }}
            open
            title="Give us feedback"
          >
            <FeedbackContent
              onSubmit={(values) => {
                onSubmit(values);
              }}
            />
          </FullModal>
        )}
        <SettingsDesktopLayout>
          <div className="mt-6">
            <h1 className="text-2xl font-medium mx-10">Give us feedback</h1>
            <div className="mx-5">
              <FeedbackContent isModal={false} onSubmit={onSubmit} />
            </div>
          </div>
        </SettingsDesktopLayout>
        {showSuccessModal && (
          <Modal
            open={showSuccessModal}
            onClose={() => {
              setShowSuccessModal(false);
            }}
            wrapperClassName="flex min-h-[100%] items-center justify-center p-4 text-center"
            panelClassName="w-full lg:max-w-[400px] rounded-lg pt-8 px-6 text-left"
          >
            <div className="mb-8 mx-auto w-max">
              <Helpingout className="keep-color" />
            </div>
            <h2 className="text-2xl font-medium mb-3">Thanks for helping out!</h2>
            <p className="text-sm text-gray-600 mb-8">
              We&apos;re always searching for ways to improve My Home Pathway, and your input is an
              important part of that.
            </p>
            <Button
              type="button"
              className="mb-8"
              onClick={() => {
                setShowSuccessModal(false);
              }}
            >
              Go back
            </Button>
          </Modal>
        )}
      </>
    )
  );
};
