import clsx from 'clsx';
import { MouseEventHandler } from 'react';

export const FilterPill = ({
  text,
  active,
  onClick,
}: {
  text: string;
  active: boolean;
  onClick: MouseEventHandler;
}) => (
  <button
    className={clsx('px-[10px] py-[5px] text-[15px] rounded', {
      'bg-[#E7E3F4] text-[#1C1A27] hover:bg-[#D0CAE9] rounded': !active,
      'bg-[#1C1A27] text-white pointer-events-none': active,
    })}
    onClick={onClick}
  >
    {text}
  </button>
);
