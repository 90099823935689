import { commonRoutes } from '@utils';
import clsx from 'clsx';
import { AdditionalResourcesIcon } from 'iconComponents/AdditionalResourcesIcon';
import { BankAccountsIcon } from 'iconComponents/BankAccountsIcon';
import { FinancesIcon } from 'iconComponents/FinancesIcon';
import { FinancialLiteracyIcon } from 'iconComponents/FinancialLiteracyIcon';
import { RewardsIcon } from 'iconComponents/RewardsIcon';
import { SettingsMenuIcon } from 'iconComponents/SettingsMenuIcon';
import { SignOutMenuIcon } from 'iconComponents/SignOutMenuIcon';
import { SummaryIcon } from 'iconComponents/SummaryIcon';
import { MessagesIcon } from 'iconComponents/MessagesIcon'
import { useRouter } from 'next/router';
import { MouseEventHandler } from 'react';
import { MenuLink } from '../menu-link';

export const BuyerLayoutMenu = ({
  welcomTitle,
  logout,
  hide,
  toggleMenu,
}: {
  welcomTitle: string;
  logout: Function;
  hide?: boolean;
  toggleMenu: MouseEventHandler;
}) => {
  const router = useRouter();

  return (
    <div
      className={clsx('mt-7 mx-auto lg:mt-0 lg:ml-10 w-[272px] card-class !pb-10 mb-5', {
        hidden: hide,
      })}
    >
      <ul className="text-[15px] font-normal">
        <li className="mb-[50px] font-semibold">
          Welcome, <span className="opacity-[0.7]">{welcomTitle}!</span>
        </li>
        <MenuLink
          icon={<SummaryIcon />}
          href={commonRoutes.buyer.auth.summary}
          toggleMenu={toggleMenu}
        >
          Summary
        </MenuLink>
        <MenuLink
          icon={<FinancialLiteracyIcon />}
          href={commonRoutes.buyer.auth.financialliteracy}
          toggleMenu={toggleMenu}
        >
          Financial Literacy
        </MenuLink>
        <MenuLink
          icon={<FinancesIcon />}
          href={commonRoutes.buyer.auth.finances}
          toggleMenu={toggleMenu}
        >
          Finances
        </MenuLink>
        <MenuLink
          icon={<BankAccountsIcon />}
          href={commonRoutes.buyer.auth.accounts}
          toggleMenu={toggleMenu}
        >
          Accounts
        </MenuLink>
        <MenuLink
          icon={<RewardsIcon />}
          href={commonRoutes.buyer.auth.rewards}
          toggleMenu={toggleMenu}
        >
          Rewards
        </MenuLink>
        <MenuLink
          icon={<AdditionalResourcesIcon />}
          href={commonRoutes.buyer.auth.additionalresources}
          toggleMenu={toggleMenu}
        >
          Additional Resources
        </MenuLink>
        <MenuLink
          icon={<MessagesIcon />}
          href={commonRoutes.buyer.auth.messages}
          toggleMenu={toggleMenu}
        >
          Messages
        </MenuLink>
        <MenuLink
          icon={<SettingsMenuIcon />}
          href={commonRoutes.buyer.auth.settings}
          toggleMenu={toggleMenu}
        >
          Settings
        </MenuLink>

        <li className="text-red">
          <button
            type="button"
            className="pl-[15px] flex gap-3 items-center hover:opacity-50"
            onClick={() => {
              logout();
              router.push('/user/get-started');
            }}
          >
            <SignOutMenuIcon />
            SIGN OUT
          </button>
        </li>
      </ul>
    </div>
  );
};
