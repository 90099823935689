import { useAuthUser, useBuyerSignupInfo, useOnboarding } from '@hooks';
import CheckFilled from '@icons/checked.svg';
import { commonRoutes } from '@utils';
import { Button } from 'components';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const Thankyou = () => {
  const { id, signedIn } = useAuthUser(true);
  const { reset } = useBuyerSignupInfo();
  const { reset: resetCompleteModal } = useOnboarding();
  const [isShareOpen, setIsShareOpen] = useState(false);
  const router = useRouter();
  useEffect(() => {
    reset();
    resetCompleteModal();
  }, []);

  return (
    <div className="flex h-full bg-center bg-no-repeat bg-cover bg-sign-up-bg">
      <div className="absolute inset-x-0 flex items-center justify-center h-full top-10">
        <div className="absolute inset-x-0 flex items-center justify-center text-center top-10">
          <Link href={commonRoutes.buyer.getStarted}>
            <a>
              <Image
                src="https://mhp-icons.s3.amazonaws.com/white-lockup.png"
                alt="Logo"
                width={130}
                height={80}
                objectFit="contain"
              />
            </a>
          </Link>
        </div>
        <div className="w-[90%] md:min-w-[554px] md:max-w-[554px] lg:justify-center mx-auto flex flex-col  p-2.5 lg:p-[0.938rem] bg-white rounded my-auto">
          <div className="flex flex-col justify-center">
            <div className="mb-2.5 mx-auto relative">
              <Image src="/images/sticker.png" width={50} height={50} alt="sticker" />
              <CheckFilled
                width={16}
                className="text-black absolute top-[18px] left-[16px] items-center justify-center"
              />
            </div>
            <h1 className="mb-2 lg:mb-4 text-[32px] font-medium font-slab text-center">
              Congratulations!
            </h1>
            <p className="text-sm text-[#635E7D] text-normal leading-6 description text-center">
              Your account has been successfully created. To start enjoying our platform and access
              all features, please complete your account set-up.
            </p>
          </div>
          <div className="flex flex-row justify-center gap-5 mt-[1.875rem]">
            {/* <Button
              type="button"
              onClick={() => {
                router.push(commonRoutes.buyer.auth.summary);
              }}
              className="w-full"
            >
              GO TO DASHBOARD
            </Button> */}
            <Button
              type="button"
              onClick={() => {
                router.push(commonRoutes.buyer.auth.summary);
              }}
              className="w-full"
            >
              ACCOUNT SET UP
            </Button>
          </div>
        </div>
      </div>
      <style jsx>{`
        .title {
          margin-top: 30px;

          font-size: 28px;
        }
        .description {
          font-size: 12px;
        }
        @media (min-height: 620px) {
          .title {
            font-size: 32px;
            margin-top: 80px;
          }
          .description {
            font-size: 14px;
          }
        }
      `}</style>
    </div>
  );
};
