import { Case, SignLayout, Switch } from '@components';
import { useBuyerSigninInfo } from '@hooks';
import ArrowLeft from '@icons/chevron-left.svg';
import { commonRoutes } from '@utils';
import { IntroductionStep } from 'components/introduction/introduction-step';
import Image from 'next/image';
import Link from 'next/link';
import { NextPageWithLayout } from 'pages/_app';
import { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import { Step1 } from './step1';
import { Step2 } from './step2';

const IntroductionDesktopSlider = () => {
  const slider = useRef<Slider>();

  return (
    <div className="w-full">
      <Slider
        prevArrow={
          <div>
            <button
              type="button"
              className="absolute border border-gray-300 w-[28px] h-[28px] rounded-full flex items-center justify-center hover:bg-gray-100"
            >
              <ArrowLeft className="keep-color" />
            </button>
          </div>
        }
        nextArrow={
          <div>
            <button
              type="button"
              className="absolute border border-gray-300 w-[28px] h-[28px] rounded-full flex items-center justify-center hover:bg-gray-100"
            >
              <ArrowLeft className="keep-color rotate-180 -mr-[1px]" />
            </button>
          </div>
        }
        ref={slider}
        {...{ dots: true, infinite: false, speed: 500 }}
      >
        <IntroductionStep
          title={
            <>
              Avoid obstacles in the way of <br /> buying your home.
            </>
          }
          description={
            <>
              Specify the price range of the property you&apos;d want to purchase, <br /> and
              we&apos;ll help you better understand credit, raise your score, and <br /> manage your
              finances.
            </>
          }
          image="/images/avoid-obstacles-desktop.png"
          priority
        />
        <IntroductionStep
          title="Make your dreams come true, step by step."
          description="Our software will then provide you practical recommendations and financial literacy quizzes based on your Home Readiness Grade."
          image="/images/dreams-true.png"
        />
        <IntroductionStep
          title="Get your loan approved with ease."
          description="Once you've finished our program and raised your grade, we'll help you get your loan pre-approved and pair you with a lender."
          image="/images/loan-approved.png"
        />
      </Slider>
    </div>
  );
};

export const BuyerSigninPage: NextPageWithLayout = () => {
  const { step: signinStep } = useBuyerSigninInfo();
  const isDesktop = useMediaQuery({ minWidth: 992 });

  return (
    <SignLayout mainClassName="!pb-0">
      <div className="flex h-full ">
        <div className="absolute inset-x-0 flex items-center justify-center text-center top-10">
          <Link href={commonRoutes.buyer.getStarted}>
            <a>
              <Image
                src="https://mhp-icons.s3.amazonaws.com/white-lockup.png"
                alt="Logo"
                width={130}
                height={80}
                objectFit="contain"
              />
            </a>
          </Link>
        </div>
        <div className="flex flex-col flex-grow w-full shrink-1">
          <div className="flex items-center justify-center h-full xs:px-0">
            <div className="flex flex-col h-3/4">
              <Switch test={signinStep}>
                <Case value={1}>
                  <Step1 />
                </Case>
                <Case value={2}>
                  <Step2 />
                </Case>
              </Switch>
            </div>
            {/* <div className="items-center justify-center hidden w-1/2 h-full bg-gray-50 lg:flex flex-fill">
              <IntroductionDesktopSlider />
              
            </div> */}
          </div>
        </div>
      </div>
      <style jsx global>{`
        /* Slider */
        .slick-slider {
          position: relative;

          display: block;
          box-sizing: border-box;

          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          -webkit-touch-callout: none;
          -khtml-user-select: none;
          -ms-touch-action: pan-y;
          touch-action: pan-y;
          -webkit-tap-highlight-color: transparent;
        }

        .slick-slider.slick-initialized {
          height: 100%;
        }

        .slick-dots {
          display: flex !important;
          gap: 12px;
          margin: 0 auto;
          width: max-content;
          font-size: 0px;
          position: absolute !important;
          left: 50%;
          transform: translateX(-50%);
          bottom: -50px;
        }

        .slick-dots li {
          display: flex;
        }

        .slick-dots button {
          width: 8px;
          height: 8px;
          background: #d1d5db;
          border-radius: 999px;
        }

        .slick-dots li.slick-active button {
          background: #111928;
        }

        .slick-arrow.slick-next {
          position: absolute;
          bottom: -33px;
          right: 19vw;
          cursor: pointer;
        }

        .slick-arrow.slick-prev[currentslide='0'] {
          display: none !important;
        }

        .slick-arrow.slick-next[currentslide='2'] {
          display: none !important;
        }

        .slick-arrow.slick-prev {
          position: absolute;
          bottom: -33px;
          left: 17.5vw;
          z-index: 1;
          cursor: pointer;
        }

        .slick-list {
          position: relative;

          display: block;
          overflow: hidden;

          margin-top: -71px;
          padding: 0;
        }
        .slick-list:focus {
          outline: none;
        }
        .slick-list.dragging {
          cursor: pointer;
          cursor: hand;
        }

        .slick-slider .slick-track,
        .slick-slider .slick-list {
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
          -o-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        }

        .slick-track {
          position: relative;
          top: 0;
          left: 0;

          display: block;
          margin-left: auto;
          margin-right: auto;
        }
        .slick-track:before,
        .slick-track:after {
          display: table;

          content: '';
        }
        .slick-track:after {
          clear: both;
        }
        .slick-loading .slick-track {
          visibility: hidden;
        }

        .slick-slide {
          display: none;
          float: left;

          height: 100%;
          min-height: 1px;
        }
        [dir='rtl'] .slick-slide {
          float: right;
        }
        .slick-slide img {
          display: block;
        }
        .slick-slide.slick-loading img {
          display: none;
        }
        .slick-slide.dragging img {
          pointer-events: none;
        }
        .slick-initialized .slick-slide {
          display: block;
        }
        .slick-loading .slick-slide {
          visibility: hidden;
        }
        .slick-vertical .slick-slide {
          display: block;

          height: auto;

          border: 1px solid transparent;
        }
        .slick-arrow.slick-hidden {
          display: none;
        }
      `}</style>
    </SignLayout>
  );
};
