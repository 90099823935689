import clsx from 'clsx';
import { CheckFilledIcon } from 'iconComponents/CheckFilledIcon';
import PendingIcon from '@icons/info.svg';
import CurrencyFormat from 'react-currency-format';

const CURRENCY_SYMBOLS = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
};

export const TransactionItem = ({
  name,
  currencyCode,
  amount,
  date,
  pending,
}: {
  name: string;
  currencyCode: string;
  amount: Number;
  date: string;
  pending: boolean;
}) => (
  <div>
    <div className="flex justify-between text-sm">
      <div className="flex">
        {pending ? (
          <div className="my-auto text-[#FFC235] w-10 h-10 rounded-full bg-[#FFF7D6] flex justify-center items-center">
            <PendingIcon />
          </div>
        ) : (
          <div className="my-auto text-[#CDFBE1]">
            <CheckFilledIcon pathColor="#10B395" width={40} />
          </div>
        )}

        <div className="ml-3 flex flex-col">
          <div className="font-semibold mb-1">{name}</div>
          <div className="text-[#9F98BE]">{date}</div>
        </div>
      </div>

      <div>
        <div className="font-semibold text-end mb-1">
          <CurrencyFormat
            prefix={CURRENCY_SYMBOLS[currencyCode] || currencyCode}
            displayType="text"
            value={`${amount.toFixed(2)}`}
          />
        </div>
      </div>
    </div>
    <div className="w-full h-[1px] bg-[#D0CAE9] my-2.5" />
  </div>
);
