import { useArrayInfo, useAuthUser, useIsMounted } from '@hooks';
import clsx from 'clsx';
import { ReactElement, ReactNode } from 'react';
import { Footer } from './footer';
import { Header, HeaderProps } from './header';
import { Avatar, Container, Grid, Typography } from '@mui/material';
import Script from 'next/script';
import { BackButtonComponent } from 'components/buyer-signup-form-v3/BackButtonComponent';
export interface LayoutProps {
  children?: ReactNode;
  headerClassName?: string;
  mainClassName?: string;
  leftMenu?: ReactNode;
}

export const Layout = ({ children, headerClassName }: LayoutProps) => {
  return (
    <>
      <Header
        className={headerClassName}
        toggleMenu={() => console.info('TODO: remove this component it doesnt seem to be used')}
        toggleHeaderLogo={() =>
          console.info('TODO: remove this component it doesnt seem to be used')
        }
      />
      <main className="flex flex-col w-full mx-auto min-h-[calc(100%-209px)] lg:min-h-[calc(100%-165px)] pb-16 lg:pb-0 bg-gradient-to-tr">
        {children}
      </main>
      <Footer />
    </>
  );
};

export const getDefaultLayout = (page: ReactElement) => {
  return <Layout>{page}</Layout>;
};

interface SignLayoutProps {
  children: ReactNode;
  mainClassName?: string;
}

export const SignLayout = ({ children, mainClassName }: SignLayoutProps) => {
  const isMounted = useIsMounted();
  return (
    <main
      className={clsx(
        'flex flex-col w-full mx-auto min-h-full bg-cover  bg-center bg-sign-up-bg',
        mainClassName,
      )}
    >
      {isMounted && <>{children}</>}
    </main>
  );
};

export const AuthLayout = ({ children, mainClassName }: SignLayoutProps) => {
  const isMounted = useIsMounted();

  const backgroundStyle = {
    backgroundImage: "url('/images/signup-bg.png')",

    // height: 'auto',

    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingY: '3rem',
  };

  return (
    <>
      {/* <div style={backgroundStyle}></div> */}
      <main
        className={clsx(
          'flex flex-col w-full mx-auto min-h-full bg-cover bg-center signup-bg h-full md:h-auto',
          mainClassName,
        )}
        style={backgroundStyle}
      >
        {isMounted && <>{children}</>}
      </main>
    </>
  );
};

export const OnboardingLayout = ({ children, mainClassName }: SignLayoutProps) => {
  const isMounted = useIsMounted();
  const { signedIn, firstName } = useAuthUser();
  const { appKey } = useArrayInfo();

  return (
    <>
      <Script src={`https://embed.array.io/cms/array-web-component.js?appKey=${appKey}`} />
      <Script src={`https://embed.array.io/cms/array-authentication-kba.js?appKey=${appKey}`} />
      <main
        className={clsx(
          'flex flex-col w-full mx-auto min-h-full bg-cover bg-center',
          mainClassName,
        )}
        style={{
          background: 'white',
        }}
      >
        {isMounted && (
          <Container
            maxWidth="xl"
            sx={{
              paddingY: {
                xs: '0.25rem',
                sm: '0.5rem',
                md: '2rem',
                lg: '3rem',
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="flex items-center justify-between">
                  <BackButtonComponent />
                  <Avatar
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '43px',
                      width: '43px',
                      border: '1px solid #C5C5C5',
                      backgroundColor: 'transparent',
                      marginLeft: 'auto',
                      cursor: 'pointer',
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontFamily: 'PlusJakartaSans',
                        fontSize: '20px',
                        fontWeight: 400,
                        lineHeight: '25.2px',
                        textAlign: 'center',
                        color: '#C5C5C5',
                        paddingBottom: '6px',
                      }}
                    >
                      {signedIn ? firstName?.charAt(0) : ''}
                    </Typography>
                  </Avatar>
                </div>
              </Grid>
            </Grid>

            {children}
          </Container>
        )}
      </main>
    </>
  );
};

export const getSignLayout = (page: ReactElement) => {
  return <SignLayout>{page}</SignLayout>;
};

export const ProfileLayout = ({
  children,
  headerClassName,
  headerContainerClassName,
  loginButton = <></>,
  rightMenu,
  leftMenu,
  mainClassName,
  logoLink,
  alwaysShowLogo,
  customHeader,
  toggleMenu,
  toggleHeaderLogo,
}: LayoutProps &
  HeaderProps & {
    headerContainerClassName?: string;
    alwaysShowLogo?: boolean;
    customHeader?: ReactNode;
  }) => {
  const isMounted = useIsMounted();
  const { signedIn } = useAuthUser();
  return (
    <>
      {customHeader ? (
        customHeader
      ) : (
        <Header
          logoLink={logoLink}
          className={clsx(headerClassName)}
          containerClassName={clsx(
            'lg:min-h-[225px] xs:min-h-[200px] sm:min-h-[150px] flex items-center lg:border-b border-gray-200 !dark:border-black-dark ',
            headerContainerClassName,
          )}
          rightMenu={rightMenu}
          loginButton={loginButton}
          alwaysShowLogo={alwaysShowLogo}
          toggleMenu={toggleMenu}
          toggleHeaderLogo={toggleHeaderLogo}
        />
      )}

      <div className="flex ">
        {isMounted && <div className="mt-[60px]">{leftMenu}</div>}

        {isMounted && (
          <main
            className={clsx(
              'flex flex-col w-full mx-auto h-full min-h-[calc(100%-201px)] lg:min-h-[calc(100%-162px)]',
              mainClassName,
              { 'pb-[78px]': signedIn, 'pb-16': !signedIn },
            )}
          >
            {children}
          </main>
        )}
      </div>
    </>
  );
};
