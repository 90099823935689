import clsx from 'clsx';
import { ErrorMessage } from 'components';
import { useFormContext, UseFormRegisterReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import ChevronDownIcon from '@icons/chevron-down.svg';
import ChevronUp from '@icons/chevron-up.svg';
import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { Label } from '../label';

interface InputSelectProps {
  showRequired?: boolean;
  name: string;
  options: { value: string | boolean | number; label: string }[];
  validations?: any;
  label?: string;
  showLabel?: boolean;
  className?: string;
  placeholder?: string;
  onChange?: ({ value, name }) => void;
  shouldRegister?: boolean;
  disabled?: boolean;
  value?: string;
  alternate?: boolean;
}

export const Select = ({
  showRequired = false,
  name,
  options,
  validations,
  label: customLabel,
  showLabel = true,
  className,
  placeholder = 'Select...',
  onChange,
  shouldRegister = true,
  value,
  disabled,
  alternate,
}: InputSelectProps) => {
  const {
    register,
    watch,
    formState: { errors, isSubmitted },
  } = useFormContext();

  const selected: string = shouldRegister ? watch(name) : value;
  const intl = useIntl();

  const formatValidations = { ...validations };

  const label = customLabel || intl.formatMessage({ id: `page.account.${name}` });

  if (!validations?.pattern?.value) {
    formatValidations.pattern = {
      value: validations?.pattern,
      message: `This ${label.replace('*', '').toLowerCase()} is invalid, please try again.`,
    };
  }

  const requiredMessage = `${label.replace('*', '').toLowerCase()} is required.`;

  if (validations?.required || showRequired) {
    formatValidations.required = requiredMessage;
  }

  const errorInvalid = errors && errors[name]?.message && isSubmitted;
  const inputRegister = shouldRegister ? register(name, formatValidations) : false;
  return (
    <div className={className}>
      {showLabel && (
        <Label
          className={clsx('mb-3', {
            '!text-gray-500': disabled,
          })}
          name={name}
        >
          {label}
        </Label>
      )}
      <div>
        <Listbox
          {...inputRegister}
          value={selected}
          disabled={disabled}
          onChange={(value) => {
            if (shouldRegister) {
              (inputRegister as UseFormRegisterReturn<string>).onChange({
                target: { value, name },
              });
            }
            if (onChange) {
              onChange({ value, name });
            }
          }}
        >
          <div
            className={clsx('relative', {
              'mt-1': !alternate,
            })}
          >
            <Listbox.Button
              className={clsx(
                'relative py-2 pr-10 text-left !bg-gray-50 dark:bg-transparent cursor-default focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm border border-gray-200 dark:border-black-dark w-full px-3 rounded bg-transparent text-sm min-h-[48px]',
                {
                  'border-[#635E7D]': alternate,
                  'border-error-500': errorInvalid,
                  '!bg-white': disabled,
                },
              )}
            >
              {selected ? (
                <span className="block truncate">
                  {options.find((option) => option.value == selected)?.label}
                </span>
              ) : (
                <span
                  className={clsx('block text-gray-400 truncate', {
                    'text-[#635E7D]': alternate,
                  })}
                >
                  {placeholder}
                </span>
              )}
              <span className="absolute inset-y-0 right-0 flex items-center pr-[12px] pointer-events-none">
                {alternate ? (
                  <ChevronUp
                    width={20}
                    className="keep-color ml-1.5 text-[#1C1A27] transition-transform rotate-[180deg]"
                  />
                ) : (
                  <ChevronDownIcon className="text-gray-400 keep-color" aria-hidden="true" />
                )}
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 w-full py-0 mt-1 overflow-auto text-base bg-white border border-gray-200 rounded max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm dark:border-black-dark dark:bg-black-background">
                {options.map((item, i) => (
                  <Listbox.Option
                    key={i}
                    className={({ active }) =>
                      `relative cursor-default select-none !bg-gray-50 py-2 px-3 border-b border-gray-200 pr-4 min-h-[48px] z-10 last:border-b-0 items-center flex text-sm dark:bg-black-background dark:text-white dark:border-black-dark ${
                        active
                          ? 'bg-primary dark:bg-primary text-gray-500 dark:text-white-500'
                          : 'text-gray-900 bg-white'
                      }`
                    }
                    value={item.value}
                  >
                    {item.label}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
        {errorInvalid && (
          <ErrorMessage error={errors[name]?.message as string} className="text-xs" />
        )}
      </div>
    </div>
  );
};
